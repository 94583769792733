.fadein-enter {
    transform: translateX(100%);
  }
  .fadein-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  .fadein-exit {
    transform: translateX(0%);
  }
  .fadein-exit-active {
    transform: translateX(-100%);
  }
  .fadein-enter-active,
  .fadein-exit-active {
    transition: opacity 200ms, transform 200ms;
  }
  
  .fadeout-enter {
    transform: translateX(-100%);
  }
  .fadeout-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  .fadeout-exit {
    transform: translateX(0%);
  }
  .fadeout-exit-active {
    transform: translateX(100%);
  }
  .fadeout-enter-active,
  .fadeout-exit-active {
    transition: opacity 200ms, transform 200ms;
  }
  
  .slide-arrow {
    height: 100%;
    display: flex;
    margin: auto;
    color: lightslategray;
    cursor: pointer;
    font-size: 2rem;
  }
  
  .slide-arrow.right {
    right: 2rem;
  }
  
  .slide-arrow.left {
    left: 2rem;
  }
  
  .imgConatiner {
    display: flex;
    margin: auto;
    justify-content: space-between;
    padding: 0px 100px;
  }
  .imgConatiner img {
    display: flex;
    margin: auto;
    max-width: 200px;
    max-height: 75px;
  }
  
  .imgblock {
    // box-shadow: 0px 2px 12.74px 0.26px rgb(212, 212, 214);
    display: flex;
    width: 30%;
    height: 100px;
    margin: 0px 20px
  }
  
  @media only screen and (max-width: 480px) {
    .imgConatiner {
      padding: 0px;
    }
    .imgblock {
      // box-shadow: 0px 2px 12.74px 0.26px rgb(212, 212, 214);
      width: 100%;
      height: 100px;
    }
    .imgConatiner img {
      width: 100%;
      height: 100px;
      // box-shadow: 0px 2px 12.74px 0.26px rgb(212, 212, 214);
    }
    .slide-arrow.right {
      right: 4rem;
      top: 220px;
    }
  
    .slide-arrow.left {
      left: 4rem;
      top: 220px;
    }
  }
  
  .conatiner {
    display:flex;
    flex-direction: column;
    top: 50%;
    left: 0;
    padding: 0px 20px;
    right: 0;
    // transform: translate(0%, -50%);
    overflow: hidden;
  }

  .homeCarouselContainer {
      display: flex;
  }

  .dots {
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }
  .fa-circle {
    padding-right: 10px;
    color: white;
  }
  .activedot {
    color: lightslategray;
  }