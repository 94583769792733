div.circleXpLabelDashboard {
    display: flex;
    flex-direction: column;
    margin: auto;
    top: -100px;
    position: relative;
    height: 100px;
}
.xpProgressDashboard {
    display: flex;
    margin: 0px auto auto auto;
}
.xpProgressDashboardBold {
    display: flex;
    margin: auto auto 0px auto;
    font-weight: 600;
}
div.dashboardXpCircleContainer {
    height: 100px;
    width: 100px;
}

div.flexLeftAlign {
    display: flex;
    margin: auto auto auto 0px
}

div.xpBarMcqGraph {
    display: flex;
    flex-direction: row;
}

svg.rc-progress-circle.circleXpBarMcq.hidden {
    display: none;
}
svg.rc-progress-circle.circleXpBarMcq {
    display: flex;
}
div.levelInfoDashboard {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
p.levelTextDashboard {
    font-weight: 600;
    font-size: 1.2rem;
    margin: auto auto 0px 0px;
    white-space: nowrap;
}
div.xpContainerDashboard {
    display: flex;
}
p.xpTextDashboard {
    white-space: nowrap;
    display: flex;
    margin: 0px auto auto 0px;
}
svg.rc-progress-circle.circleXpBarDashboard {
    height: 100px;
    width: 100px;
    display: flex;
}
div.mcqXpCircleContainer {
    height: 60px;
    width: 60px;
}
div.levelInfoMcq {
    display: flex;
    margin-left: 10px;
    flex-direction: column;
}
p.levelTextMcq {
    margin: auto auto 0px 0px;
    white-space: nowrap;
}
p.xpTextMcq {
    margin: 0px auto auto 0px;
    white-space: nowrap;
}

div.btn-floating.mobileStreak.pulse {
    background-color: orange;
    margin-right: 10px;
    display: flex;
    width: 30px;
    height: 30px;
    line-height: 30px;
}

p.mcqStreak {
    margin: auto;
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: 600;
}
div.blueStreakContainerMcq {
    background-color: #525174;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    margin-top: 10px;
}

div.noStreakContainer {
    display: flex;
    margin: auto 10px auto auto;
}

div.btn-floating.pulse.streakContainer {
    display: flex;
    margin: auto 10px auto auto;
    background-color: orange;
}

div.gamificationContainerXpBar{
    display: flex;
    flex-direction: row;
    margin: auto;
    padding: 5px;
    border-radius: 5px;
}
div.xpBarDashboard {
    display: flex;
}
.xpProgressDashboardBoldMax {
    margin: auto;
    font-weight: 600;
}
div.avatarDashboard {
    margin-left: 20px;
}
img.responsive-img.dashAvatar {
    height: 100px;
}

//For mobile quizHeader
div.mcqXpCircleContainerMobile {
    display: flex;
    flex-direction: column;
}

div.mcqGraphHeaderTopContainer {
    display: flex;
    margin-top: 5px
}

div.mcqGraphHeaderTopContainerThird {
    display: flex;
    margin: auto;
    width: 33%;
}

a.material-icons.mcqGraphHeaderTopContainerLeft {
    display: flex;
    margin: auto auto 2px 0px;
    color: gray;
    font-size: 3rem
}

i.material-icons.mcqGraphHeaderTopContainerRight {
    display: flex;
    margin: auto 0px 2px auto;
    color: gray;
    font-size: 3rem
}

p.levelTextMcqMobile {
    display: flex;
    margin: auto
}

p.xpTextMcqMobile {
    display: flex;
    margin: auto 10px auto auto;
    font-weight: 600
}

div.streakContainerMobile {
    display: flex
}

div.barContainerMobile {
    display: flex;
    flex-direction: column;
    margin-top: -10px
}

div.mcqGraphHeaderBottomContainer {
    display: flex;
    margin-top: 5px;
}

div.mcqGraphHeaderQuestionContainer {
    display: flex;
    margin: auto
}

p.mcqGraphHeaderQuestion {
    display: flex;
    margin: 0px auto;
    font-weight: 600;
    font-size: 1.5rem;
    color: #525174
}

p.mcqGraphHeaderHashtag {
    display: flex;
    margin: auto 5px 0px 0px;
    font-size: 2rem;
    font-weight: 600;
    color: #525174
}

i.material-icons.fireMobile {
    display: flex;
    margin: auto 0px auto 12px;
    width: auto;
    position: relative;
    color: red;
    font-size: 20px;
    top: -1px
}

i.material-icons.fireMobile.streak {
    display: flex;
    margin: auto;
    width: auto;
    position: relative;
    color: red;
    font-size: 20px;
    line-height: inherit;
    top: 0px
}

p.mcqStreakMobile {
    margin: auto auto auto 0px;
    font-weight: 600
}