button.fancy-btn.flag {
  cursor: pointer;
}

button.fancy-btn.noFlag {
  cursor: not-allowed;
  background-color: gray;
}

//Parent container
div.containerTeacherDataContainer {
  margin: 0 auto;
  max-width: 1000px;
  width: 90%;
}

div.teacherDashboardContainer {
    display: flex;
    width: 90%;
    height: 90vh;
    margin: auto;
    max-width: 1000px;
}


div.containerTeacherDashRow {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
}

//Sidebar
div.teacherDashSidebarContainer {
  display: flex;
    flex-direction: column;
    margin: 0px 10px 0px auto;
    border: #bfbfbf solid 1px;
    border-radius: 5px;
    background-color: white;
    height: 85vh;
    overflow-y: auto
}

div.btn-floating.brainBox {
    height: 70px;
    width: 70px;
    box-shadow: none;
    border: 2px solid black;
    display: flex;
    background-color: #e5e6ff;
}

div.teacherDashSidebarBrainBoxflexRow{
  display: flex;
  flex-direction: row;
}

p.brain {
  font-size: 3rem;
  margin: 15px auto;
}

div.textContainerTeacherDashWelcomeBox{
  display: flex;
  flex-direction: column;
  margin: auto auto auto 10px;
}

div.teacherDashSidebarBrainBox {
  display: flex;
  margin: 30px auto 10px 20px;
}

p.welcomeText{
  margin: auto auto 2px 0px;
  font-weight: 600;
  font-size: 1.4rem;
  color: #525174;
  line-height: 1;
}

p.welcomeTextSchool{
  margin: 2px auto auto 0px;
  font-size: 1rem;
  color: rgb(161, 161, 161);
  line-height: 1;
}

div.sidebarTeacherDashButtonContainer {
  display: flex;
  flex-direction: row;
  margin: 0px 30px
}

a.sidebarLink.TeacherDash {
  display: flex;
  font-weight: 600;
  color: #348aa7;
    &:hover {
    background-color: rgb(216, 216, 218);
  }
}

div.sidebarLink.TeacherDash {
  display: flex;
  font-weight: 600;
  color:#348aa7;
    &:hover {
      background-color: rgb(216, 216, 218);
      cursor: pointer;
    }
}


p.sidebarLinkText {
  display: flex;
  margin: auto 10px;
  white-space: nowrap;
}

//Right sidebar
div.teacherDashSidebarContainerRight {
    width: 350px;
    display: flex;
    flex-direction: column;
}

div.videoContainerTeacherDash {
  border: #bfbfbf solid 1px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  width: 100%;
  margin: 0px auto auto auto;
}

div.videoContainerTeacherDashFrame {
  display: flex;
  margin: auto auto 20px auto
}

div.popularContainerTeacherDash {
  border: #bfbfbf solid 1px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  width: 100%;
  margin: auto auto 0px auto;
}
//Header

div.teacherDashboardHeader { 
  display: flex;
  border-radius: 10px;
  margin: 10px auto auto auto;
  width: 100%
}

div.flexRowTeacherDashHeader {
  display: flex;
  height: 15vh
}

div.flexColumnTeacherDash {
  display: flex;
  flex-direction: column;
  width: 100%;
}

div.flexSixty {
  display: flex;
  flex-direction: column;
  width: 100%;
}

div.flexRowTeacherData {
    padding: 10px;
    border: grey 1px solid;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
}

div.blueContainerTeacherDashSelect {
  display: flex;
  border-radius: 10px;
  background-color: #525174;
  border: #525174 2px solid;
  height: 52px;
  width: 100%;
  margin: auto 5px auto auto;
}

a.blueContainerTeacherDash {
  display: flex;
  border-radius: 10px;
  background-color: #525174;
  height: 52px;
  padding: 0px 10px;
  margin: auto auto auto 5px;
  transition: color .3s ease, border-color .3s ease, transform .3s ease;
   
    &:hover {
      transform: scale(1.01);
    }
}

p.classSelectLabelTeacherDash {
  margin: auto;
  display: flex;
  color: white;
  font-weight: 600;
}

p.manageClassesTeacherDash {
  margin: auto 5px auto auto;
  display: flex;
  color: white;
  font-weight: 600;
}

div.flexSettingsTeacherDash {
  display: flex;
  margin: auto auto auto 5px;
  color: rgb(184, 184, 184)
}

div.mainButtonContainerTeacherDash {
  display: flex;
  width: 50%;
  margin: auto;
  height: auto;
}

div.buttonContainerTeacherDash {
  display: flex;
  flex-direction: column;
  margin: auto;
}

a.btn-floating.TeacherDash {
  height: 70px;
  width: 70px;
  margin: auto;
  display: flex;
}

p.teacherDashEmoji {
  font-size: 40px;
  text-align: center;
  margin: auto;
}

p.buttonLabelTeacherDash {
  margin: auto;
  font-weight: 600;
}



// Classview/ quizview Toggle button

p.toggleViewLeft{
  font-size: 1.2rem;
  display: flex;
  margin: auto 0px auto auto;
}

p.toggleViewRight{
  font-size: 1.2rem;
  display: flex;
  margin: auto auto auto 0px;
}

div.toggleContainer {
  display: flex;
    flex-direction: row;
    height: 52px;
    width: 300px;
    margin: 10px auto auto auto;
    background-color: #525174;
    border-radius: 10px;
    color: white;
}

div.react-toggle {
  display: flex;
  margin: auto 5px auto 5px;
}

//The header of the columns
div.sortBlock {
  display: flex;
  flex-direction: row;
}

div.nameBlock {
  display: flex;
  flex-direction: row;
  margin: auto 3px;
  width: 55%;
  height: 40px;
  background-color: #513b56;
}

p.nameBlockText {
  display: flex;
  margin: auto auto auto 5%;
  color: white;
  font-size: 1.5rem;
}

div.completionBlock {
  display: flex;
  flex-direction: row;
  margin: auto 3px;
  width: 22%;
  height: 40px;
  background-color: #513b56;
}

p.completionBlockText {
  display: flex;
  margin: auto;
  color: white;
  font-size: 1.5rem;
}

div.accuracyBlock {
  display: flex;
  flex-direction: row;
  margin: auto 3px;
  width: 23%;
  height: 40px;
  background-color: #513b56;
}

p.accuracyBlockText {
  display: flex;
  margin: auto;
  color: white;
  font-size: 1.5rem;
}

select.selectSort {
  display:flex
}



div.classContainerTeacherDash {
  margin: auto auto auto 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;
  max-width: 800px; 
}

div.classContainerTeacherDashRight {
  border:#bfbfbf solid 1px;
  border-radius: 5px;
  padding: 10px;
  margin: 10px auto auto 10px;
  display: flex;
  flex-direction: column;
  height: 85vh;
  width: 60% 
}

div.classContainerTeacherDataContainer {
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 85vh;
  width: 100%
}

div.flexRowTeacherDashMyDataContainer {
  display: flex;
  flex-direction: row;
  margin: auto
}

div.flexColumnTeacherDashDataContainer {
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  width: 100%
}

div.teacherDashStudentDataContainer {
  margin-top: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

div.flexRowMyDataContainer {
    margin: 10px auto;
    display: flex;
    flex-direction: row;
}

p.teacherDashMyData {
  font-weight: 600;
  font-size: 1.2rem;
  color: #4d4d4d;
  display: flex;
  margin: auto 5px auto auto;
}

i.material-icons.myData {
  display: flex;
  margin: auto auto auto 5px;
  font-size: 1.2rem;
  color: #4d4d4d
}

i.material-icons.feedListTeacherData {
  display: none
}

// The main container element
.Collapsible {
  border-radius: 5px;
  transition: transform .5s ease
}

.Collapsible:hover {
  transform: scale(1.01);
}


//The content within the collaspable area
.Collapsible__contentInner {
  margin-left: 5%;
  width: 80%;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    // &:last-child {
    //   margin-bottom: 0;
    // }
  }
}

div.questionAttempts {
  display:flex;
  flex-direction: row;
  border-radius: 10px;
  background-color: #5dd39e;
  padding: 2px;
  margin: 5px auto 5px 2%
}

ul.quizAttemptsContainer {
  margin: auto;
}

li.classViewQuestion {
  margin-left: 2%;
  width:90%;
  padding: 2px;
}

//The link which when clicked opens the collapsable area

div.studentName{
  display: flex;
  flex-direction: row;
  width: 95%;

  &:before {
    font-family: 'FontAwesome';
    content: '\f007';
    display: flex;
    margin: auto 2% auto 3%
  }
}

p.studentNameText {
  color: grey;
  margin: auto 0px auto 0px;
  display: block;
  width: 30%;
  flex-direction: row;
  white-space: nowrap;
  text-overflow: ellipsis;
}

p.completion {
  margin: auto;
  display: flex;
  width: 30%;
  flex-direction: row;

  &:before {
    font-family: 'FontAwesome';
    content: '\f080';
    display: flex;
    margin: auto 2% auto auto
  }
}

p.accuracy {
  margin: auto 2% auto auto;
  display: flex;
  width: 30%;
  flex-direction: row;

  &:before {
    font-family: 'FontAwesome';
    content: '\f140';
    display: flex;
    margin: auto 2% auto auto
  }
}

div.themeName{
  display: flex;
  flex-direction: row;
  width: 95%;

  &:before {
    font-family: 'FontAwesome';
    content: '\f02d';
    display: flex;
    margin: auto 2% auto 4%
  }
}

p.themeNameText {
  margin: auto 0px auto 0px;
  display: flex;
  width: 40%;
  flex-direction: row;
}

p.themeAccuracy {
  margin: auto 5% auto auto;
  display: flex;
  width: 30%;
  flex-direction: row;

  &:before {
    font-family: 'FontAwesome';
    content: '\f140';
    display: flex;
    margin: auto 2% auto auto
  }

}

p.themeCompletion {
  margin: auto;
  display: flex;
  width: 30%;
  flex-direction: row;

  &:before {
    font-family: 'FontAwesome';
    content: '\f080';
    display: flex;
    margin: auto 2% auto auto
  }

}

//QuizLandingPage

p.themeNameTextQuizDash {
  font-size: 1.5rem;
  margin: auto 0px auto 0px;
  display: flex;
  width: 30%;
  flex-direction: row;

}

p.themeInfo {
  margin:  auto 10px auto auto;
  display: flex;
  flex-direction: row;

  &:before {
    font-family: 'FontAwesome';
    content: '\f212';
    display: flex;
    margin: auto 5px auto auto
  }
}


.Collapsible__trigger {
  display: flex;
  background-color: #e5e5ff;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border-radius: 5px;
  padding: 10px;
  color: black;
  margin-top: 2px;
  margin-bottom: 2px;
  overflow: hidden;

  &:before {
    font-family: 'FontAwesome';
    content: '\f107';
    transition: transform 300ms;
  }

  &.is-open {
    &:before {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
  
  
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}

@media (max-width: 480px) {
  div.containerTeacherDataContainer {
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
    height: 90vh;
    overflow: scroll;
  }

  div.flexRow.TeacherDataContainer {
    padding: 0px 5px
  }

  p.teacherDashMyData {
    font-size: 1.3rem;
    margin: auto;
  }
  
  i.material-icons.myData {
    display: none;
  }

  div.flexRowTeacherData {
    display: none
  }

  div.flexColumnTeacherDashDataContainer {
    height: 20vh
  }
  
  div.teacherDashFlexRowTeacherDataContainer {
    display:flex;
    margin: auto;
    height: 8vh;
    width: 90%
  }

  div.teacherDashStudentDataContainer {
    height: 60vh
  }

  div.teacherDashStudentDataContainer.quizView {
    height: 70vh
  }

  div.teacherDashboardContainer {
    display: flex;
    width: 100%;
    height: 90vh;
    margin: auto;
    max-width: 1000px;
  }

  div.containerTeacherDashRow {
    display: flex;
    flex-direction: row;
    margin: 0px auto auto auto;
    width: 100%;
    height: 100%
  }

  div.teacherDashSidebarContainer {
    width: 100%;
    height: 100%;
    margin: 0px auto auto auto;
    background-color: initial;
    border: none;
    border-radius: initial;
  }
  
  div.teacherDashSidebarBrainBox {
    display: flex;
    margin: auto auto 0px 0px;
    background-color: #525174;
    width: 100%;
    padding: 10px;
    border-radius: initial;
  }
  

  p.welcomeText {
    color: #348aa7
  }

  p.welcomeTextSchool {
    color: white;
    font-weight: 600;
  }

  div.teacherDashMobileButtonContainer {
    display: flex;
    flex-direction: column;
    height: 80%;
    padding: 10px;
    margin: auto;
    width: 100%;
  }

  div.teacherDashTwoButtonContainer {
    margin: 2px;
    display: flex;
    height: 100%
  }

  div.sidebarTeacherDashButtonContainer {
    margin: auto;
    max-width: 80%;
  }

  p.sidebarLinkText {
    margin: auto 10px;
    text-align: center;
    white-space: initial;
  }

  a.sidebarLink.TeacherDash {
    box-shadow:  1px 1px 1px #ccc;
    background-color: white;
    margin: 2px 3px;
    border-radius: 5px;
    width: 50%;
  }

  div.sidebarLink.TeacherDash {
    box-shadow:  1px 1px 1px #ccc;
    background-color: white;
    margin: 2px 3px;
    border-radius: 5px;
    width: 50%;
  }

  div.sidebarLink.TeacherDash.Settings {
    background-color: white;
    margin: 2px auto 2px auto;
    border-radius: 5px;
    width: 100%;
    height: 100%;
  }

  div.classContainerTeacherDash {
    display: none
  }


  div.classContainer {
    width: 95%;
  }

  p.completion {
    display: none;
  }
  
  p.accuracy {
    margin: auto 5% auto auto;
    display: none;
    width: 20%;
    flex-direction: row;

  }

  div.studentName{
    &:before {
      margin: auto 2% auto 10%
    }
  }

  p.themeAccuracy {
    display: none;
  }
  
  p.themeCompletion {
    display: none
  }

  div.themeName{
    &:before {
      font-family: 'FontAwesome';
      content: '\f02d';
      display: flex;
      margin: auto 2% auto 10%
    }
  }

  i.material-icons.feedListTeacherData {
    display: flex;
    margin: auto 0px auto 0px;
    width: 30px;
  }

}