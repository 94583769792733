
div.popupReport {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

div.closeContainer {
    margin: 0px 0px 0px auto;
    display: flex;

    &:hover {
        cursor: pointer;
    }
}

div.feedbackBtn {
    background-color: initial;
    border: 1px solid grey;
    width: 280px;
    padding: 10px;
    margin: 10px auto;
    display: flex;
    flex-direction: row;

    &:hover {
        cursor: pointer;
    }
}

div.feedbackBtn.active {
    background-color: black;
    color: white;
    border: 1px solid grey;
    width: 280px;
    padding: 10px;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
}

div.reportButtonContainer{
    display: flex;
    flex-direction: row;
    width: 100%
}

i.material-icons.feedback {
    display: flex;
    margin: auto 0px auto 20px;
    width: 30%
}

p.reportText {
    white-space: nowrap;
    display: flex;
    margin: auto auto auto 0px;
    color: grey;
    font-size: 1rem;
}

textarea.feedbacktextArea {
    display: flex;
    margin: auto auto 10px auto;
    height: 100px;
    width: 280px
}

p.feedbackThanksText {
    font-weight: 600;
    color: gray;
    font-size: 1.2rem
}

p.feedbackThanksEmoji {
    font-size: 4rem;
    margin: auto
}