div.react-pdf__Document {
    display: flex;
    margin: auto;
}

p.pdfViewerToolsDivider {
    margin: auto 20px
}

p.pdfViewerButtonDivider {
    margin: auto 10px
}

div.pdfViewerToolsContainer {
    background-color: rgb(50, 54, 57);
    height: 56px;
    align-items: center;
    color: white;
    display: flex;
    padding: 0 16px
}

div.pdfViewerToolsInnerContainer {
    display: flex;
    margin: auto;
}

div.pdfViewerDocumentContainer {
    background-color: rgb(92, 99, 95);
    display: flex;
    margin: auto;
}