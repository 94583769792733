div.innerThemeContainer2 { 
    display: flex;
    width: 100%;
    background-color: transparent;
    margin: 5px auto;
    padding: 0px 20px 0px 20px;
    border-radius: 5px;
}

div.innerThemeContainer2.lockedTrial { 
    background-color: #6a726e;
    padding: 12px 20px 12px 20px;
    opacity: 0.7;
}

div.innerThemeContainer2.lockedTrial:before { 
    display: flex;
    font-family: 'FontAwesome';
    content: '\f023';
    color: black;
}

.btn-floating.pulse.unlockedTrial {
    margin-left: 10px
}

p.innerHighScore {
    color: white;
    font-size: 1.8rem;
    text-align: right;
    height: auto;
    width: 25%;
    margin: auto;
}

ul.bigContainer {
    width: 100%;
    font-size: 1.8rem; 
}

li.themeListContainer {
    width: 100%;
    display: flex;
}

a.quizName {
    color: white;
    width: 100%;
}

li.quizName.lockedTrial {
    display: flex;
    width: 95%;
}

p.quizNameLockedTrial {
    color:  black;
    margin: auto auto auto 20px;
    opacity: 0.9;
    width: 100%;
}

p.marginAutoQuizLandingTrial {
    margin: auto;
    display: flex;
}

li.quizName2 {
    margin: 0px;
    display: flex;

}

@media (max-width: 480px) {
    ul.bigContainer {
        width: 100%;
        font-size: 1.2rem; 
    }
    a.quizName {
        color: white;
        width: 100%
    }
    p.innerHighScore {
        color: black;
        text-align: right;
        height: auto;
        margin: auto;
    }
    div.innerThemeContainer2 { 
        display: flex;
        width: 90%;
        margin: 5px 0px 5px 0px
    }
    div.innerThemeContainer2.lockedTrial { 
        background-color: #6a726e;
        padding: 12px 10px 12px 10px;
        opacity: 0.7;
        width: 100%;
    }
}
