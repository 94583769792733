//Pre-existing code from classView

label.select-label {
    display: initial;
    position: initial;
    font-size: 1.2rem;
}

div.flexColumn {
    display: flex;
    flex-direction: column;
}

div.btn.centre.onboarding {
    max-width: 300px;
    display: flex;
    margin: auto;
}

p.onboarding {
    display: flex;
    margin: auto
}

div.classviewPlaceholder {
    border: grey 1px dashed;
    padding: 20px;
    height: 300px;
    margin-bottom: 10px;
}

//studentContainer
div.studentDataContainerStudentView {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

div.studentViewLeftFlexContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}

div.studentViewFlexContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #e5e5ff;
    border-radius: 5px;
}

div.studentNameContainerStudentView {
    display: flex;
    flex-direction: column;
    margin: 0px auto 10px auto;
    border-radius: 5px;
    width: 30%;
    background-color: #fafaff;
}

div.studentNameContainerStudentViewTop  {
    font-weight: 600;
    display: flex;
    border-radius: 5px;
    font-size: 1.2rem;
    margin: 20px auto;
    color: #4d4d4d
}

div.collapsibleContainerStudentView {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: 100%;
    border: #bfbfbf solid 1px;
    border-radius: 5px;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden
}

i.material-icons.studentNameContainerStudentView {
    display: flex;
    margin: auto 5px auto auto
}

p.studentNameTextStudentView {
    display: flex;
    margin: auto auto auto 5px
}

p.studentViewPerformanceLabel {
    font-weight: 600;
    margin: 10px auto
}

p.studentViewPerformanceMeasure {
    margin: 5px auto 20px auto;
    font-size: 1.2rem;
}

p.studentDataTitleStudentView {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4d4d4d;
}


p.completionStudentView {
    margin: auto;
    display: flex;
    width: 30%;
    flex-direction: row;
  
    &:before {
      font-family: 'FontAwesome';
      content: '\f080';
      display: flex;
      margin: auto 2% auto auto
    }
  }
  
  p.accuracyStudentView {
    margin: auto 2% auto auto;
    display: flex;
    width: 30%;
    flex-direction: row;
  
    &:before {
      font-family: 'FontAwesome';
      content: '\f140';
      display: flex;
      margin: auto 2% auto auto
    }
  }
  
  div.themeNameStudentView{
    display: flex;
    flex-direction: row;
    width: 95%;
  
  }
  
  p.themeNameTextStudentView {
    margin: auto 0px auto 0px;
    display: flex;
    width: 40%;
    flex-direction: row;
  }
  
  p.themeAccuracyStudentView {
    margin: auto 5% auto auto;
    display: flex;
    width: 30%;
    flex-direction: row;
  }
  
  p.themeCompletionStudentView {
    margin: auto;
    display: flex;
    width: 30%;
    flex-direction: row;
  
  }
  
  //QuizLandingPage
  
  p.themeNameTextQuizDash {
    font-size: 1.5rem;
    margin: auto 0px auto 0px;
    display: flex;
    width: 30%;
    flex-direction: row;
  
  }
  
  p.themeInfo {
    margin:  auto 10px auto auto;
    display: flex;
    flex-direction: row;
  
    &:before {
      font-family: 'FontAwesome';
      content: '\f212';
      display: flex;
      margin: auto 5px auto auto
    }
  }
  
  
  .Collapsible__trigger {
    display: flex;
    background-color: #e5e5ff;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    border-radius: 5px;
    padding: 10px;
    color: black;
    margin-top: 2px;
    margin-bottom: 2px;
    overflow: hidden;
  
    &:before {
      font-family: 'FontAwesome';
      content: '\f107';
      transition: transform 300ms;
    }
  
    &.is-open {
      &:before {
        transform: rotateZ(180deg);
      }
    }
  
    &.is-disabled {
      opacity: 0.5;
      background-color: grey;
    }
  }
  

