div.popup-container.spinner {
    z-index: 6;
}

div.preloader-wrapper.active {
    display: flex;
    margin: auto;
}

p.spinnerReloadText {
    margin: auto;
    color: white
}

i.material-icons.spinnerReload {
    margin: auto;
    color: white
}

div.flexAuto.spinnerReload {
    margin-top: 10px;
    cursor: pointer
}