div.mathsTextQuestionContainer.true {
    display: none
}

div.mathsAnswerTextContainer {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    flex: calc(100% - 75px) 1;
    margin: auto 20px;
    transition: color .3s ease;
}



@media (max-width: 480px) {
    div.mathsTextQuestionContainer.true {
        display: inline
    }
}