.footer {
    position: relative;
    background-color: transparent;
    color: black;
    border-top: solid 1px grey;
    width: 80%;
    margin: auto auto 20px auto
}

div.footerContainerRight{
    float: right;
    display: flex;
    flex-direction: row;
}

img.twitter {
    height: 18px;
    float: right;
    top: 3px;
    position: relative;
    margin: auto 5px
}

.grey-textFooter {
    color: #9e9e9e !important;
    margin-left: 5px;
    margin-right: 5px;
}


@media (max-width: 480px) {
    div.footerContainerRight{
        display: none;
    }
}