div.mobileContainer.assign {
    width: 80%;
    display: flex;
    margin: auto
}

div.bigCalendarContainer.newAssignment {
    display: flex;
    flex-direction: column;
}

//Assignment table
div.assignmentDateContainer {
    display: flex;
    background-color: #525174;
    color: white;
    font-weight: 600
}

//View assignments container

div.viewAssignmentsTopContainer{
    display: flex;
    flex-direction: column;
    min-width: 300px;
    margin: auto;
}

div.viewAssignmentsContainer {
    display: flex;
    width: 100%;
    max-width: 300px;
    height: 50vh;
    border: 3px solid #525174;
    border-radius: 5px;
    padding: 5px 2px;
    flex-direction: column;
}

div.scrollableViewAssignmentsContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

div.viewAssignmentsContainer.greyed {
    opacity: 0.3;
    pointer-events: none;
    
}

div.assignmentDateContainer {
    color: white;
    background-color: #525174;
    margin: 2px 5px;
    padding: 2px 10px;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
    }
}

p.assignmentDateContainerText {
    display: flex;
    margin-left: 5px
}

div.calendarButton {
    display: flex;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #525174;
    padding: 10px;
    color: white;
    max-width: 180px;
    
    &:hover {
        cursor: pointer;
    }
}

div.calendarButton.greyed {
    opacity: 0.3;
    pointer-events: none;
}

i.material-icons.calendarButton {
    display: flex;
    margin: auto 5px auto auto
}

p.calendarButton {
    display: flex;
    margin: auto auto auto 5px;
    font-weight: 600;
}


// New Assignment
div.newAssignmentSelectContainer {
    display: flex;
    margin: 20px auto;
    width: 100%
}

div.newAssignmentSelectContainer.timeframe {
    width: 90%
}

div.newAssignment100Container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    min-height: 70vh;

}

div.bigCalendarContainer.newAssignment {
    display: flex;
    flex-direction: column;
    margin: auto;
    min-width: 400px;
}

select.newAssignment {
    display: flex;
    background-color: whitesmoke;
    border: 1px solid #9e9e9e;
    border-radius: 2px;
    width: 75%;
    font-weight: 600;
}

div.newAssignmentCloseContainer {
    display: flex;
    height: auto;
    width: 100%
}

div.chooseClassContainer.newAssignment {
    display: flex;
    width: 100%;
    margin: 10px auto 10px auto;
    max-width: 700px;
}

div.chooseClassContainer.newAssignment.top {
    display: flex;
    width: 100%;
    margin: 10px auto 10px auto;
    max-width: 700px;
}

i.material-icons.closeNewAssignment {
    color: grey;
    display: flex;
    margin: auto 0px auto auto;

    &:hover {
        cursor: pointer;
    }
}

div.chooseActivity.newAssigmnent {
    display: flex;
    width: 100%;
    margin: auto
}

div.setDateContainer {
    display: flex
}

div.twoCalendarContainer.newAssignment {
    display: flex;
    margin: 10px auto
}

div.calendarContainer.newAssignment {
    display: flex;
    flex-direction: row;
    margin: auto;
    opacity: 0.3;
    pointer-events: none;
}

div.calendarContainer.newAssignment.true {
    opacity: 1;
    pointer-events: initial;
}

div.calendarContainerSingle.newAssignment {
    display: flex;
    flex-direction: column;
    margin: auto 10px
}

div.endCalendar.false {
    opacity: 0.3;
    pointer-events: none;
}

button.react-calendar__tile--now {
    background-color: initial;
}

button.react-calendar__tile--active {
    background: #006edc;
    color:black !important;
    font-weight: 600;
}

div.computerContainer {
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 500px;
    margin: auto;
}

img.computer {
    width: 800px;
    height: 500px;
    margin: auto;
    position: absolute;
    display: flex;
    z-index: -3
}

div.droppableArea.newAssignment {
    border: none;
    width: 598px;
    height: 375px;
    display: flex;
    flex-direction: column;
    margin: 36px auto auto 105px;
}

p.computerHeader {
    margin: 0px auto;
    color: #525174;
    font-weight: 600;
}

p.computerTaskNumber {
    margin: auto;
    font-weight: 600;
}

a.flashcardPreviewText.columnAssign {
    display: flex;
    flex-direction: column;
    margin: auto auto auto 5px;
    width: 70%;
    max-height: 100%;
    overflow: hidden;
    text-transform: capitalize;
}

div.assignComputerButtonContainer {
    display: flex;
}

p.computerTeacherMessage {
    display: flex;
    margin: 10px 10px 0px 10px;
    font-weight: 600;
}

p.computerTextMessage {
    display: flex;
    margin: 0px 10px 10px 10px
}

div.assignButtonTextContainer {
    display:flex;
    flex-direction: column;
}

div.assignButton.newAssignment {
    min-width: 200px;
    max-width: 270px;
    height: 50px;
    padding: 0px 5px;
    overflow: hidden;
    border-radius: 5px;
    background-color: #525174;
    display: flex;
    color: white;
    margin: 20px auto auto auto;
    
    &:hover {
        cursor: pointer;
    }
}

div.assignButton.newAssignment.popup {
    width: 150px;
}

p.assignButtonDateText {
    display: flex;
    margin: -5px auto auto 5px;
    white-space: nowrap;
}

div.assignButton.addMessage {
    height: 50px;
    padding: 10px;
    border-radius: 5px;
    background-color: #525174;
    display: flex;
    color: white;
    margin: 20px auto auto 5px; 
    
    &:hover {
        cursor: pointer;
    }
}

div.assignButton.editMessage {
    height: 50px;
    width: 180px;
    padding: 10px;
    border-radius: 5px;
    background-color: #525174;
    display: flex;
    color: white;
    margin: 20px 5px auto 5px; 
    
    &:hover {
        cursor: pointer;
    }
}

div.assignButton.noClasses {
    height: 50px;
    width: 180px;
    padding: 10px;
    border-radius: 20px;
    background-color: #525174;
    display: flex;
    color: white;
    margin: 20px auto auto 5px; 
    
    &:hover {
        cursor: pointer;
    }
}

div.assignComputerButtonContainer {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 100%;
}

div.twoButtonAssignContainer {
    display: flex;
    flex-direction: row;
    margin: auto auto auto 0px;
}

div.assignButton.removeMessage {
    height: 50px;
    width: 180px;
    padding: 10px;
    border-radius: 5px;
    background-color: #525174;
    display: flex;
    color: white;
    margin: 20px auto auto 5px; 
    
    &:hover {
        cursor: pointer;
    }
}

div.assignButton.newAssignment.greyed {
    opacity: 0.3;
    &:hover {
        cursor: initial;
    }
}

p.assignButtonText {
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    margin: auto;
}

i.material-icons.assignButton {
    font-weight: 600;
    display: flex;
    margin: auto 5px auto 5px;
}

//NewTask
div.container.newTask {
    background-color: white;
}

div.newTask100Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px
}

p.newTaskTitle {
    text-align: center;
    margin: 0px auto;
    font-size: 1.2rem;
    font-weight: 600;
    color: #525174
}

p.chooseClassText.newAssignment {
    font-weight: 600;
    color: #525174;
    font-size: 1rem;
    margin: 10px;
    text-align: left;
    white-space: nowrap;
}

select.newTask {
    display: block;
    background-color: whitesmoke;
    border-color: #9e9e9e;
    width: 90%;
    max-width: 500px;
    margin: auto 10px auto auto;
}

div.flexColumnEndCalendar {
    display: flex;
    flex-direction: column;
}

div.endCalendar {
    display: flex;
    margin: auto
}

div.newTaskVerified {
    display: flex;
    margin: auto
}

.computerTextMessage p {
    margin: auto;
    width: 100%
}

i.material-icons.newTaskTick {
    display: flex;
    margin: 30px 10px 30px auto;
    font-size: 2rem;
    font-weight: 600;
    color: green
}

p.newTaskVerified {
    color: rgb(73, 73, 73);
    display: flex;
    margin: auto auto auto 10px;
    font-weight: 600;
    font-size: 1.5rem
}

div.returnButton.newAssignment {
    height: 50px;
    width: 200px;
    padding: 10px;
    border-radius: 20px;
    background-color: #525174;
    display: flex;
    color: white;
    margin: 20px auto; 
    
    &:hover {
        cursor: pointer;
    }
    display: flex;
    white-space: nowrap;
}

//New message

div.editMCQFormContainer.newMessage {
    height: 200px;
    margin: 10px;
    width: initial
}

div.assignButton.confirmMessage {
    height: 50px;
    width: 175px;
    padding: 10px;
    border-radius: 20px;
    background-color: #525174;
    display: flex;
    color: white;
    margin: 20px auto; 
    
    &:hover {
        cursor: pointer;
    }
}

div.assignButton.confirmMessage.greyed {
    opacity: 0.3;
    
    &:hover {
        cursor: initial;
    }
}

//No class

p.noClassText {
    text-align: center;
    display: flex;
    margin: 20px auto 20px auto;
}

div.container.newTask.noClass {
    border-radius: 10px;
    display: flex;
    max-width: 500px
}

div.container.newTask.noClass.Calendar {
    flex-direction: column;
    padding: 10px
}

a.assignButton.noClasses {
    height: 50px;
    width: 180px;
    padding: 10px;
    border-radius: 20px;
    background-color: #525174;
    display: flex;
    color: white;
    margin: 20px 2px auto 2px; 
    
    &:hover {
        cursor: pointer;
    }
}

div.noticeboardBoundingBox {
    border: 1px solid black;
    height: 300px;
    overflow-y: auto;
}

div.chooseClassContainer.newAssignment.top.dateSelection {
    display: none
}

@media (max-width: 480px) {
    .popup-container.mobileCalendar {
        flex-direction: column;
    }

    i.material-icons.mobileCalendar {
        color:white
    }

    div.closeContainer.mobileCalendar {
        width: 350px;
        background: #525174;
        margin: 0px;
    }

    div.twoCalendarContainer.newAssignment {
        margin: 0px
    }

    select.newAssignment {
        min-width: 275px
    }

    p.chooseClassText.newAssignment {
        min-width: 50px;
    }

    i.material-icons.chooseDate{
        margin: auto 5px auto auto
    }

    div.mobileContainer.assign {
        margin: 0px
    }

    div.chooseDateContainer.newAssignment {
        display: flex;
        min-width: 275px;
        background-color: whitesmoke;
        border: 3px solid #9e9e9e;
        border-radius: 2px;
        width: 60%;
        font-weight: 600;
        height: 3em;
        padding: 0px 2px 1px;
    }

    p.chooseDateContainer.newAssignment {
        font-weight: normal;
        display: block;
        white-space: pre;
        min-height: 1.2em;
        padding: 0px 2px 1px;
        margin: auto auto auto 2px;
        font-weight: 600;
    }
    
    div.bigCalendarContainer.newAssignment {
        margin: 0px;
        min-width: initial
    }

    div.chooseClassContainer.newAssignment.top {
        margin: 10px auto 0px auto;
    }

    div.chooseClassContainer.newAssignment.top.dateSelection {
        margin: 10px auto 0px auto;
        display: flex;
        opacity: 0.3;
        pointer-events: none;
    }

    div.chooseClassContainer.newAssignment.top.dateSelection.true {
        margin: 10px auto 0px auto;
        opacity: 1;
        pointer-events: initial;
    }


    div.calendarContainerSingle.newAssignment {
        display: none;
        flex-direction: column;
        margin: 0px;
    }

    div.calendarContainerSingle.newAssignmentShow {
        display: flex;
        flex-direction: column;
        margin: 0px;
        opacity: 1;
        pointer-events: initial;
    }

    div.calendarContainerSingle.newAssignmentShow {
        display: flex;
        flex-direction: column;
        margin: 0px;
        opacity: 1;
        pointer-events: initial;
    }

    div.newAssignment100Container {
        margin: 0px;
        flex-direction: column
    }

    div.newTask100Container {
        padding: 15px 5px 5px 5px
    }

    div.newAssignmentSelectContainer {
        margin: 0px auto
    }

    div.twoButtonAssignContainer {
        margin: auto auto 10px auto;
    }

    p.computerHeader {
        margin: 0px 10px 0px 10px;
        font-size: 1.2rem;
    }

    img.computer {
        width: 0px;
        height: 0px;
        margin: auto;
        position: initial;
        display: none;
        z-index: -3;
    }

    div.viewAssignmentsContainer {
        height: 30vh;
        width: 100%;
        max-width: none;
        margin: 5px auto
    }

    div.computerContainer {
        width: initial;
        height: initial;
        margin: 0px auto auto auto;
    }

    div.droppableArea.newAssignment {
        border: 1px solid;
        width: initial;
        height: initial;
        display: flex;
        flex-direction: column;
        margin: 5px 2px;
    }

    p.chooseClassText.newAssignment.newTask {
        width: 200px
    }

    div.assignButton.newAssignment {
        height: 50px;
        width: 150px;
        margin-bottom: 10px;
        margin-top: 10px
    }

    div.assignButton.removeMessage {
        display: none
    }

    div.assignButton.editMessage {
        display: none;
    }

    div.assignButton.addMessage {
        height: 50px;
        width: 150px;
        margin-top: 10px
    }

    div.assignButton.noClasses {
        height: 50px;
        width: 150px;
    }

    a.assignButton.noClasses {
        height: 50px;
        width: 150px;
    }

    p.assignButtonText {
        font-size: 1rem;
    }

    i.material-icons.assignButton.mobile {
        display: none;
    }

    div.newAssignmentSelectContainer.timeframe {
        width: initial
    }

    div.calendarButton {
        display: none;
    }

    div.noticeboardBoundingBox {
        height: 200px;
        overflow-y: auto;
    }
}