div.popupNewQuiz {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    margin: auto;
    flex-direction: column;
    width: 85%;
    min-height: 300px;
    padding: 20px 30px 30px 30px;
}

p.popupNewQuiz {
    color: rgb(53, 53, 53);
    font-size: 1.2rem;
}

img.newQuiz{
    max-width: 100%;
    height: auto;
    margin: 20px auto;
}

h5.needHelp.diagnostics {
    color: #525174
}

a.diagnosticsGoButton {
    display: flex;
    flex-direction: row;
    margin: 10px auto;
    border-radius: 10px;
    background-color: #26a69a;
    padding: 10px 20px;

    &:hover {
        transform: scale(1.01);
        cursor: pointer;
    }
}

@media (max-width: 480px) {
    div.popupNewQuiz {
        max-width: 95%;
    }
}