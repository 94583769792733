div.popupRenameQuizNewMCQForm {
    padding: 10px;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

div.returnToQuizzesContainer {
    display: flex;
    width: 175px;
    margin: auto 0px auto auto;
}

p.returnToQuizzes {
    margin: auto 0px auto auto;
    color: #9e9e9e;
}