
div.flexColumn.halfWidth {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    max-width: 800px;
}

p.diagnosticsHeading {
    display: flex;
    margin: 20px auto 10px 10px;
    font-weight: 600;
    font-size: 2rem;
    color: #777777;
}

a.flexRow.diagnosticsTeacherSettings {
    display: flex;
    margin: auto 0px auto auto;

    &:hover {
        cursor: pointer;
    }
}



p.diagnosticsTeacherText {
    display: flex;
    margin: 0px 0px 0px auto;
    text-align: right;
}

p.diagnosticsTeacherText.bottom {
    margin: 0px 0px auto auto;
}

i.material-icons.TeacherText {
    display: flex;
    margin: auto auto auto 10px;
}

div.diagnosticsSixtyfive {
    display: flex;
    margin: 0px auto;
    flex-direction: column;
    border: solid 4px #525174;
    border-radius: 20px;
    width: 100%;
    height: 40vh;
    padding: 5px;
    overflow-y: auto;

}

div.diagnosticsSixtyfive.questions {
    height: 10vh;
}

div.input-field.diagnostics {
    display: flex;
    margin: auto 5px;
}

div.flexRow.diagnostics.input {
    margin: auto;
    flex-direction: row;
}

button.btn-floating.diagnostics.left {
    display: flex;
    margin: auto 5px auto auto;
}

button.btn-floating.diagnostics.left.false {
    display: none;
    margin: auto 5px auto auto;
}

button.btn-floating.diagnostics.right {
    display: flex;
    margin: auto auto auto 5px;
}

input#numberOfQuestions.diagnostics {
    background-color: white;
    color: #525174;
    text-align: center;
    width: 40px;
    margin: auto;
    border: 1px solid #525174;
    border-radius: 5px;
    font-size: 1.4rem;
    font-weight: 600;
}

div.diagnosticsCategoryButton {
    background-color: #525174;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-radius: 10px;

    &:hover {
        transform: scale(1.005);
        cursor: pointer;
    }
}

div.diagnosticsCategoryButton {
    background-color: #525174;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-radius: 10px;

    &:hover {
        transform: scale(1.005);
        cursor: pointer;
    }
}

p.diagnosticsCategoryButtonText {
    color: white;
    margin: 5px auto 5px 20px;
    font-weight: 600;
    font-size: 1.5rem;
}


p.diagnosticsCategoryButtonText.plus {
    margin: 5px 20px 5px auto;
}

i.material-icons.closeCategoryMenu {
    display: flex;
    margin: 5px 10px 5px auto;
    color: grey;
    float: right;
    transition: .3s ease;

    &:hover {
        transform: scale(1.005);
        cursor: pointer;
    }
}

div.categoryMenuflexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
}

div.categoryMenuCategoryContainer {
    background-color: #bce784;
    margin: 5px 10px;
    border-radius: 10px;
    display: flex;
    transition: .3s ease;

    &:hover {
        transform: scale(1.01);
        cursor: pointer;
    }
}

p.categoryMenuCategoryName {
    color: #525174;
    display: flex;
    margin: 10px auto 10px 20px;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2rem;
    text-align: left;
}

p.categoryMenuCategoryName.right {
    margin: 10px 0px 10px auto;
    width: 10%;
}

div.diagnosticsGoButton {
    display: flex;
    flex-direction: row;
    margin: 10px auto;
    border-radius: 10px;
    background-color: #26a69a;
    padding: 10px 20px;

    &:hover {
        transform: scale(1.01);
        cursor: pointer;
    }
}

p.diagnosticsGoButtonText {
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0px auto;
}

//MCQgraph diagnostics


div.diagnosticsInfoButton {
    display: flex;
    background-color: #525174;
    margin: auto 0px auto 0px;
    padding: 10px;
    border-radius: 5px;
    // width: 150px;

    &:hover {
        transform: scale(1.01);
        cursor: pointer;
    }
}

p.diagnosticsInfoButtonText {
    font-weight: 600;
    display: flex;
    margin: auto auto auto 10px;
    color: white;
}

div.diagnosticsInfo {
    display: flex;
    background-color: transparent;
    margin: auto auto auto 10px;
    padding: 5px;
    border-radius: 5px;
}

p.diagnosticsInfoText {
    font-weight: 600;
    display: flex;
    margin: auto auto auto 0px;
    color:#525174;
}

// Diagnostics analytics

p.diagnosticsTitle {
    color: gray;
    font-size: 1.5rem;
    font-weight: 600;
}

div.analyticsQuestionContainer {
    background-color: #bce784;
    margin: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

div.analyticsTextContainer {
    height: 60vh;
    overflow-y: auto;
    display: flex;
    margin: 0px auto 10px auto;
}

div.analyticsQuestionNumberContainer {
    display: flex;
    white-space: nowrap;
}

div.analyticsMiddleQuestionContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
    overflow: hidden;
}

p.analyticsQuestionNumberText {
    color: black;
    display: flex;
    margin: auto 20px auto 10px;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2rem;
}

p.analyticsQuestionText {
    display: flex;
    margin: 0px auto 10px 10px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
}

p.analyticsQuestionDetails {
    color: #525174;
    display: flex;
    margin: 10px auto 5px 10px;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2rem;
    white-space: nowrap;

}

p.analyticsQuestionQuizName {
    color: #808080;
    display: flex;
    margin: 0px auto 5px 10px;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2rem;
    white-space: nowrap;
}

div.analyticsScrollableContainer {
    padding: 5px;
    border: grey 1px solid;
    margin: 10px;
    border-radius: 5px;
}

div.analyticsAnsweredContainer {
    display: flex;
    flex-direction: column;
    margin: auto 20px;
    width: 30%;
}

p.notAnswered {
    margin: auto 0px auto auto;
    font-weight: 600;
    color: grey
}

i.material-icons.analyticsRight {
    margin: auto 0px auto auto;
    font-weight: 600;
    color: green
}

i.material-icons.analyticsWrong {
    margin: auto 0px auto auto;
    font-weight: 600;
    color: red
}

a.fancy-btn.analytics {
    margin-bottom: 10px;
}

a.diagnosticsInfoButton.mobileDiagnostics {
    display: none
}

div.flexRow.mobileDiagnostics {
    flex-direction: row;
}

@media (max-width: 480px) {
    div.diagnosticsInfoButton {
        margin: 10px 0px auto auto
    }

    a.diagnosticsInfoButton.mobileDiagnostics {
        padding: 10px 20px;
        background-color:transparent;
        margin: 10px auto auto 0px;
        display: flex;
        flex-direction: row;
    }

    i.material-icons.mobileDiagnostics {
        color: #525174
    }

    p.diagnosticsInfoButtonText.mobileDiagnostics {
        color: #525174
    }
}