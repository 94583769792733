div.studentAssignmentTwoFlex {
    display: flex;
    margin: 20px auto;
    width: 100%;
}

div.setDateContainer {
    display: flex;
    margin: auto;
}

p.setDate.studentAssignment {
    text-align: center;
    width: 100%;
    display: flex;
    font-weight: 600;
    color: #525174;
}

p.studentAssignmentDate {
    text-align: center;
    margin: 0px auto;
    padding: 10px;
    font-size: 1.5rem;
    color: white;
    font-weight: 600;
    width: 100%;
    background-color: #525174;
}

p.studentAssignmentTeacherMessage {
    width: 100%;
    font-weight: 600;
    color: #525174;
    font-size: 1.2rem;
    margin: 5px auto
}

div.teacherMessageText p {
    color: #525174;
    width: 100%;
    display: flex;
    margin: 5px auto
}

div.studentAssignmentsContainer {
    margin: 0px 10px;
    border: 3px solid #525174;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50vh;
    overflow-y: auto;
}

div.studentAssignmentsTaskContainer {
    width: 100%;
    display: flex;
    margin: 2px auto;
    padding: 10px;
    background-color: #ffc728;
}

@media (max-width: 480px) {
    div.mobileContainer.assign {
        width: initial;
    }
    
    div.studentAssignmentTwoFlex {
        margin: 0px;
        flex-direction: column;
    }

    div.studentAssignmentsContainer {
        margin: 10px;
        width: initial;
        border: none;
        border-radius: initial;
        padding: 0px;
        height:initial
    }

}