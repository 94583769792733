.circleContainerStyle {
    width: '150px';
    height: '150px';
    display: 'inline-block';
  };



div.dashboardElementCircle {
    margin-left: 10px;
    display: flex;
}

div.themeBox {
    width: 70%;
    display: flex;
    flex-direction: row;
    margin: 0px auto;
    position: relative;
    padding-bottom: 20px;
}

div.themeBoxText {
    display: flex;
    margin: auto auto auto 0px
}

.circleLabel {
    position: absolute;
    width: 75px;
    height: 75px;
    top: 9px;
    text-align: center;
    font-size: 1.2rem;
}

.circleLink {
    position: absolute;
    top: 76px;
    left: 100px
}

a.dashboardElementTestAccuracy {
    font-size: 1rem;
    display: flex;
    margin: auto auto auto 10px;
    color: white;
    border-radius: 5px;
    background-color: #525174;
    vertical-align: middle;
    padding: 3px 6px 
}