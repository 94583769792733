div.themeContainer { 
    display: flex;
    width: 90%;
    background-color: #ec407a;
    margin: 5px auto 5px auto;
    padding: 0 20px 0 20px;
    border-radius: 5px;
}

.highScore {
    color: black;
    text-align: right;
    height: auto;
}

p.highScore {
    margin: 0 auto 0 auto;
    width: 80%
}

ul.bigContainer {
    width: 100%
}

li.themeListContainer {
    width: 100%;
}

a.quizName {
    color: white;
}