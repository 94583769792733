p.existingRestrictions {
    font-weight: 600;
    font-size: 1.6rem;
    margin: 10px auto
}

div.restrictionsFiftyLeft {
    display: flex;
    margin: 20px 5px auto auto;
    width: 50%;
    border: #525174 solid 2px;
    border-radius: 10px;
    height: 80vh
}

div.restrictionsFiftyRight {
    display: flex;
    flex-direction: column;
    margin: 20px auto auto 5px;
    width: 50%;
    padding: 0px 5px 10px 5px;
    border: #525174 solid 2px;
    border-radius: 10px;
    height: 80vh
}

//Classes container
a.noClasses.restrictions.manage {
    display: flex;
    flex-direction: row;
}

p.assignButtonText.restrictions {
    margin: auto 0px auto auto
}

p.classesRestrictions {
    display: flex;
    margin: 10px auto 0px auto;
    font-weight: 600;
    font-size: 1.2rem;
}

p.addRestrictionsText {
    display: flex;
    margin: 0px auto 5px auto;
    font-size: 1rem;
    
}

div.restrictionsScrollableClassContainer {
    height: 100%;
    overflow-y: auto;
}

div.flexColumn.restrictionsNoClasses {
    height: 100%
}

p.restrictionsAddClasses {
    font-weight: 600;
    margin: auto auto 10px auto;
    font-size: 1.2rem;
    color: #525174;
}

p.restrictionsAddClasses.global {
    margin: auto
}

div.restrictionsNoClassesContainer {
    height: 100%;
    border: grey 1px solid;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #efedf7;
}

div.restrictionsNoClassesContainer.global {
    margin: 10px
}



a.assignButton.noClasses.restrictions {
    margin: 5px auto auto auto
}


div.restrictionsClassContainer {
    height: 50px;
    display: flex;
    flex-direction: column;
    background-color: #525174;
    margin: 5px auto;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
    }
}

div.restrictionsClassContainer.global {
    background-color: #e75353;
}

div.restrictionsClassContainer.global.true {
    background-color: #27be13;
}

span.material-icons.globalRestrictions {
    margin-left: 5px;
}

p.restrictionsClassText {
    color: white;
    display: flex;
    margin: auto auto auto 10px;
    font-weight: 600;
}

p.restrictionsClassText.noRestrictions {
    margin: 0px auto auto 10px;
    font-weight: 500;
    opacity: 0.6;
}

//Restrictions container
div.restrictionsContainerInitial {
    padding: 20px;
    display: flex;
    width: 100%;
}

p.restrictionsInitialText {
    font-weight: 600;
    margin: auto;
    font-size: 1.2rem;
    color: #525174;
}

div.flexColumn.getRestrictions {
    width: 100%
}

div.restrictionsCollapsibleContainer {
    padding: 5px 10px;
    overflow-y: auto;
}

div.categoryContainers.restrictions {
    display: flex;
    flex-direction: column;
}

div.themeContainer.restrictions {
    display: flex;
    height: 40px;
    width: 100%;
    background-color: #e5e5ff;
    margin: 2px auto 2px auto;
    padding: 0 20px 0 20px;
    border-radius: 5px;
}

p.restrictionsQuizName {
    color: #525174;
    display: flex;
    margin: auto auto auto 10px;
}

i.restrictionsQuizVisibility {
    color: #525174;
    display: flex;
    margin: auto 10px auto auto;
}

//Restrictions warning

div.paddedRestrictionsWarning {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

p.categoryMenuCategoryName.restrictionsWarning {
    margin: 10px
}

p.categoryMenuCategoryName.restrictionsWarning.centre {
    display: flex;
    margin: auto
}

p.categoryMenuCategoryName.restrictionsWarning.themes {
    color: grey
}

div.verifyButtonNo.centre {
    margin: 20px auto auto auto 
}