.nav-wrapper {
    height: 65px;
    background-color: #525174;
}

.nav-wrapperMobile {
    display: none; 
    height: 65px;
    background-color: #525174;
}

.navbarLogo {
    width: 45px;
    height: 45px;
    margin-top: 10px
}

.logo {
    height: 45px;
}

a.left {
    height:45px;
}

ul.right {
    height: 45px
}

li.oxbridgeNavLink {
    display: flex
}



li.subjectDropdownNavbar {
    height: 64px;
    display: flex;
}

select.subjectDropdownNavbar {
    display: flex;
    margin: auto;
}

div.navBarSubject {
    display: flex;
    margin: auto;
    transition: .3s ease;
    
    &:hover {
        transform: scale(1.05);
        cursor: pointer;
    }
}

p.navBarSubjectName {
    margin: auto 10px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.2rem;
}

i.material-icons.navBar {
    display: flex;
    margin: auto
}

@media (max-width: 480px) {
    .nav-wrapper {
        display: none;
    }

    p.btn.btn-floating {
        display: none
    }

    li.subjectDropdownNavbar {
        display: none;
    }
}

@media (max-width: 450px) {
    .nav-wrapperMobile {
        display: flex;
    }

    .dashboardNav {
        display: none
    }
    li.subjectDropdownNavbar {
        display: none;
    }
    li.oxbridgeNavLink {
        display: none;
    }    
}