.topThemeContainer {
    padding: 10px;
    width: 70%;
    display: flex;
    margin: auto;
    flex-direction: column;
}

/* Choose subject */

div.selectSubjectContainer {
    display: flex;
    margin: auto auto 10px auto;
    flex-direction: column;
    width: 100%;
}

p.chooseSubjectText {
    color:#513b56;
    margin: 0px auto 20px auto;
    font-size: 2rem;
    font-weight: 600;
}

div.chooseSubjectContainer {
    display: flex;
    width: 100%;
    margin: auto;
}

div.chooseSubjectPairDivider {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 800px;
    margin: auto
}

div.chooseSubjectCard {
    display: flex;
    flex-direction: row;
    margin: 10px;
    height: 30vh;
    width: 50%;
    max-width: 400px;
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
}

div.chooseSubjectCard:hover {
    transform: scale(1.01);
}

div.comingSoonContainer {
    z-index: 2;
    position: absolute;
    width: 50%;
    height: 30vh;
    display: flex;
    margin: 10px;
    padding: 10px;
    max-width: 400px;
    border-radius: 20px;
}

p.comingSoonText {
    color: rgb(87, 87, 87);
    font-size: 2.5rem;
    font-weight: 600;
    display: flex;
    margin: auto;
}

div.chooseSubjectCard.greyed {
    background-color: rgb(194, 194, 194);
    opacity: 0.4;
    cursor: initial;
}

div.subjectTextContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
}

p.chooseSubjectName {
    text-align: center;
    display: flex;
    margin: auto auto auto auto;
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: 600;
    color:#513b56
}

p.chooseSubjectName.greyed{
    opacity: 0.4;
}

div.subjectToggle {
    display: flex;
    cursor: pointer;
}

p.subjectToggle {
    color: grey;
    margin: auto auto auto 10px
}

img.chooseSubjectImage{
    display: flex;
    margin: auto;
    width: 60%;
    max-height: 25vh;
}

img.chooseSubjectImage.greyed{
    opacity: 0.5;
}

div.pledge.flexRow {
    display: flex;
    flex-direction: row;
}

div.pledge.flexColumn {
    display: flex;
    flex-direction: column;
}

p.trialHeader.small {
    display: flex;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    color: white;
}

/* Subject container below */


div.categoryContainers {
    width: 100%;
    margin: auto auto auto auto
}

div.trialHeaderContainer {
    display: flex;
    flex-direction: column;
    margin: auto auto 20px auto;
    background-color: #5dd39e;
    padding: 20px 20px;
    width: 100%;
}

div.trialHeaderTextContainer {
    display:flex;
    flex-direction: column;
    margin: auto;
    max-width: 1000px
}

div.trialHeaderTextContainerTop{
    display: flex;
    flex-direction: row;
}

h4.trialHeader {
    text-align: center;
    display: flex;
    margin: auto 10px auto auto;
    color: rgb(255, 255, 255);
    font-weight: 600;
    white-space: nowrap;
}

p.trialText {
    color: white;
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    margin: 10px auto auto auto;
    text-align: center;
}

a.trialSignupButton {
    white-space: nowrap;
    max-height: 75px;
    padding: 10px 20px;
    background-color: #513b56;
    display: flex;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    margin : auto 50px
}

a.trialSignupButton:after {
    display: flex;
    margin: auto 10px;
    font-family: 'FontAwesome';
    content: '\f09c';
}

img.ellipses {
    position: absolute;
    opacity: 0.2;
    width: 50%;
    height: auto;
    top: -30px;
    right: 100px;
}

@media (max-width: 480px) {
    .quizLandingFormContainer {
        width: 100%;
    }

    .topThemeContainer {
        width: 90%;
    }

    div.trialHeaderTextContainerTop{
        display: flex;
        flex-direction: column;
    }

    p.chooseSubjectText {
        color:#513b56;
        margin: 0px auto 10px auto;
        font-size: 1.2rem;
        font-weight: 600;
    }

    div.trialHeaderContainer {
        display: flex;
        flex-direction: column;
        margin: auto auto 5px auto;
        background-color: #5dd39e;
        padding: 10px;
        width: 100%;
    }

    h4.trialHeader {
        white-space: inherit;
        font-size: 1.5rem;
        padding: 10px;
        display: flex;
        margin: auto;
        color: rgb(255, 255, 255);
    }

    p.trialHeader.small {
        margin: -5px auto 10px auto
    }

    p.trialText {
        font-size: 1rem;
        font-weight: 600;
    }
    
    a.trialSignupButton {
        margin: auto auto 10px auto
    }

    /* Choose subject */
    div.topThemeContainer.chooseSubject {
        width: 95%
    }

    img.chooseSubjectImage{
        display: flex;
        margin: auto;
        width: 60%;
    }

    div.chooseSubjectCard {
        flex-direction: column;
        width: 50%;
        height: 25vh;
        margin: 5px
    }

    div.comingSoonContainer {
        width: 45%;
        height: 25vh;
        margin: 5px
    }

    p.chooseSubjectName {
        line-height: 1;
        text-align: center;
        display: flex;
        margin: auto auto auto auto;
        font-size: 1rem;
        font-weight: 600;
        color:#513b56
    }

    p.comingSoonText {
        text-align: center;
        line-height: 1.2;
        color: rgb(87, 87, 87);
        font-size: 1.5rem;
        font-weight: 600;
        display: flex;
        margin: auto;
    }

}