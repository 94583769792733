div.popupCustomiseFeed {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    margin: auto;
    flex-direction: column;
    padding: 10px;
    width: 300px;
}

div.customiseFeedContainer {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 90%;
    height: 100%
}

div.btn-floating.closePopupCustomiseFeed {
    display: flex;
    margin: 0px 0px 0px auto 
}

label.select-label.customView {
    display: flex;
    margin: 0px 0px 0px 5px
}

a.manageClassesButtonCustomiseFeed {
    padding: 10px 20px;
    width: 100%;
    color: white;
    font-weight: 600;
    background-color: #525174;
    border-radius: 5px;
    display: flex;
    margin: 1px auto
}

a.studentDataButtonCustomiseFeed {
    padding: 10px 20px;
    width: 100%;
    color: white;
    font-weight: 600;
    background-color: #525174;
    border-radius: 5px;
    display: flex;
    margin: 1px auto
}


i.material-icons.settingsButtonCustomiseFeed {
    margin: auto auto auto 5px
}