.formContainer {
    width: 80%;
    min-height: 40px;
    background-color: #0094da;
    margin: 2px auto 2px auto;
    border-radius: 5px;
    color: black;
    font-size: 1.8rem;
    word-spacing: 0.5rem;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.questionTitle {
    margin: 10px auto 10px auto;
    padding: 10px 30px 10px;
}

.formQuestion {
    width: 95%;
    min-height: 150px;
    margin: 0px auto 0px auto;
    border-radius: 5px;
    display: flex;
    padding: 10px;
}

.answerContainer {
    width: 95%;
    padding: 10px;
    margin: 5px auto 5px auto;
    display: flex;
    flex-direction: row;
    border-style: solid;
}

.formContainerTop {
    width: 100%;
    margin: 0px auto 10px auto;
}

.answerContainerleft {
    width: 70%;
}

.answerContainerright {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.correctAnswer {
    margin: auto;
}

.formAnswer {
    width: 100%;
    border-radius: 5px;
    display:inline-block;
    margin: auto;
}

.formExposition {
    width: 100%;
    min-height: 100px;
    border-radius: 5px;
    display:inline-block;
    margin: auto;
}

.correctButton {
    display: flex;
    margin: auto !important;
}


  
.addQuestionContainer {
    width: 80%;
    min-height: 40px;
    background-color: #0094da;
    margin: 2px auto 2px auto;
    border-radius: 5px;
    color: black;
    font-size: 1.8rem;
    word-spacing: 0.5rem;
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.addQuestionButton {
    margin: auto auto auto 30px;
    padding: 10px 0px 10px;
    display: inline-flex;
    width: 100%;    
}

.addQuestionButtonPlus {
    font-family: 'FontAwesome';
    content: '\f107';
    display: inline-flex;
    transition: transform 300ms;
    margin: auto;
    padding-right: 10px;
}



p.uploadButton {
    font-size: 1rem;
    height: 90%;
    display: inline;
    margin: auto;
    color: white;
    border-radius: 5px;
    background-color: #525174;
    vertical-align: middle;
    padding: 3px 6px 
}

p.uploadButtonGreyed {
    font-size: 1rem;
    height: 90%;
    display: inline;
    margin: auto;
    color: white;
    border-radius: 5px;
    background-color:  rgb(224, 224, 224);
    vertical-align: middle;
    padding: 3px 6px 
}

p.uploadButton:hover {
    font-size: 1rem;
    height: 90%;
    display: inline;
    margin: auto;
    color: white;
    border-radius: 5px;
    background-color: #3d3b7c;
    vertical-align: middle;
    padding: 3px 6px;
    cursor: pointer 
}

select.graphQuestion {
    display: block
}

label.graphQuestionLabel {
    position: relative
}

p.greyedOutButton{
    background-color: rgb(224, 224, 224);
    color: white;
    text-align: center;
    letter-spacing: .5px;
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-right: 10px
}

button.btn.pink.lighten-1 {
    margin-right: 10px
}

p.warning {
    color:red;
    margin: 0;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.submitDiv {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

input.chooseFile {
    width: 30%
}
