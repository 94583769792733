hr.comfyMargin {
    margin: 30px auto
}

a.link.comfyMargin {
    display: flex;
    margin: 10px auto
}

div.helpBulletContainer {
    display: flex;
    flex-direction: column;
    margin: 20px auto
}

p.helpBullet {
    color: rgb(85, 81, 81);
    margin: 2px 0px
}