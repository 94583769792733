div.negativeContainerTop {
    display: flex;
    flex-direction: column;
    margin: auto;
}

div.negativeContainer {
    margin: 20px auto auto auto;
    display: flex;
    flex-direction: row;
}

div.negativeCardTemplate {
    display: flex;
    border: 1px grey solid;
    width: 100px;
    height: 100px;
    margin: auto 5px
}

p.negativeText {
    display: flex;
    margin: auto;
    font-size: 3rem;
}

p.negativeTextSymbol {
    display: flex;
    margin: 0px auto auto auto;
    position: relative;
    top: -5px;
    font-size: 5rem;
    margin-block-start: 0px;
}

p.negativeTextSymbolGreen {
    display: flex;
    margin: auto;
    font-size: 3rem;
    color: green
}
p.negativeTextSymbolRed {
    display: flex;
    margin: auto;
    font-size: 3rem;
    color: red
}

button.negativeButton {
    display: flex;
    margin: 20px auto auto auto;
    width: 150px;
    height: 50px;
}

.negativeButton p {
    display: flex;
    margin: auto;
}