div.flexRow.resourceUpload {
    max-width: 1200px;
    margin: 0px auto;
}


//Resource Directory
div.directoryTopContainer{
    display: flex;
    flex-direction: row;
}

i.material-icons.resourceDirectory.refresh {
    margin: auto auto auto 5px;

    &:hover {
        transform: scale(1.01);
        cursor: pointer;
    }
}

p.resourceType {
    display: flex;
    margin: auto auto auto 5px
}

h5.grey-text.text-darken-3.resourceDirectory {
    display: flex;
    margin-left: auto; 
}

div.directoryContainer {
    height: 80vh;
    border: purple 1px solid;
    padding: 10px;
    border-radius: 5px;
    overflow-y: auto;
}

div.directoryRow {
    margin: 5px auto;
    padding: 5px;
    background-color: white;
    border-radius: 2px;
}

div.tableRow {
    display: flex;
    flex-direction: row;
}

p.tableText {
    display: flex;
    margin: auto;
    width: 100px;
}

p.tableText.paper {
    font-weight: 600;
    margin: 0px;
    width: 100%;
}