div.bigContainerNewMCQForm {
    width: 90%;
    margin: 0 auto;
    max-width: 1280px;
}


//Top container
div.newMCQFormTopContainer {
    display: flex;
    margin: auto;
    width: 80%
}

div.newMCQFormSaveContainerFlexRow {
    display: flex;
    flex-direction: row;
    margin: auto auto auto 20px
}

div.flexColumnNewMCQFormTopContainer {
    margin: 25px 10px auto auto;
    display: flex;
    flex-direction: column;
}

div.flexRow.NewMCQForm {
    min-width: 1000px;
}

div.flexRowNewMCQFormSaveContainer {
    display: flex;
    flex-direction: row;
    margin: auto 20px
}

div.btn-floating.newMCQFormSave {
    display: flex;
    margin: auto;
}

div.newMCQFormSaveContainerFlexColumn {
    display: flex;
    flex-direction: column;
    margin: auto
}

p.NewMCQFormSave {
    display: flex;
    margin: auto auto 0px 5px;
    font-weight: 600;
}

p.NewMCQFormAutosave {
    display: flex;
    margin: 5px auto auto 15px;
    color: rgb(158, 158, 158)
}

p.NewMCQFormTagWarning {
    display: flex;
    margin: 5px auto auto 15px;
    color: red
}

p.NewMCQFormSaved {
    display: flex;
    margin: 5px auto auto 15px;
    color: green
}

p.NewMCQFormPreview {
    display: flex;
    margin: auto auto auto 5px;
    font-weight: 600;
}


div.input-field.newMCQForm {
    width: 55%;
    margin: 20px 0px auto auto
}

p.NewMCQFormTitleLabel {
    margin: 0px auto 0px auto;
    color: grey
}

//Main container

textArea.MCQFormQuestionText {
    font-size: 20px;
    color: white;
    text-align: center;
    border: grey 1px dashed;
    padding: 10px;
    border-radius: 5px;
    height: 150px;
    font-family: 'Roboto', 'Fira Sans','Work Sans', sans-serif;
}

textArea.MCQFormQuestionTextMobile {
    display: none;
    font-size: 20px;
    color: white;
    text-align: center;
    border: grey 1px dashed;
    padding: 10px;
    border-radius: 5px;
    height: auto;
    font-family: 'Roboto', 'Fira Sans','Work Sans', sans-serif;
}


textarea.MCQFormAnswerText {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    flex: calc(100% - 75px);
    margin: 5px;
    height: auto;
    border:none;
    resize: none;
    
}

div.newMCQFormAnswersCKEditor {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    flex: calc(100% - 75px) 1;
    margin: 5px;
    height: auto;
    border: none;
    resize: none;
    overflow:auto
}

div.editMCQFormContainer {
    width: 100%;
    margin-left: 5px;
    border: grey 1px dashed;
    padding: 2px
}

div.gamificationTopContainerMCQForm {
    display: flex;
    flex-direction: row;
    margin: 0px auto 0px auto;
    width: 100%;
}

div.mcqQuestionContainerTextMCQForm {
    display: flex;
    margin: auto 0px;
}

div.mcqQuestionContainerMCQForm {
    display: flex;
    font-size: 20px;
    color: white;
    text-align: center;
    border: grey 1px dashed;
    padding: 10px;
    border-radius: 5px;
    height: 150px;
    font-family: 'Roboto', 'Fira Sans','Work Sans', sans-serif;
}

div.mcqQuestionContainerMobileMCQForm {
    display: none;
}

div.imageMCQForm {
    flex-direction: column;
    display: flex;
    width: 250px;
    height: 270px;
    margin: 10px;
}

div.flashcardFormImagePlaceholderMCQForm {
    position: relative;
    top: 0px;
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 250px;
    border: grey 1px dashed;
    margin: auto auto auto auto;
}

div.flexColumnPlaceHolderTextMCQForm {
    margin: auto;
    display: flex;
    flex-direction: column;
}

p.placeHolderTextMCQForm {
    margin: 5px auto 5px 0px
}

div.toggleMCQForm {
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    z-index: 1;
    position: relative;
    top: -5px;
}

input.chooseFileflashcardFormImageMCQForm {
    width: auto;
    margin-top: 5px
}

//Tagging buttons
div.MCFormTagger.btn {
    margin: auto 2px
}

p.MCQFormTagger {
    margin: auto
}

//Difficulty selection

div.newMCQFormDifficultyContainer {
    display: flex;
    width: 30%;
    margin: 10px 0px 10px auto;
}

p.newMCQFormDifficultyLabel {
    display: flex;
    margin: auto 10px;
    white-space: nowrap;
}

select.newMCQFormDifficultyDropdown {
    display: flex;
    height: auto
}



//Sidebar nav
div.MCQFormRightContainer {
    display: flex;
    padding: 5px;
}

div.droppableAreaMCQForm {
    height: 430px;
    min-width: 150px;
    border: 2px dashed grey;
    padding: 5px;
    margin: 10px;
    overflow: auto;
}

div.btn-floating.newQuizFormDraggable.pulse {
    width: 15px;
    height: 15px;
    margin: 0px 0px 0px auto;
    display: flex;
    background-color: orange;
    box-shadow: none;
}

div.flashcardPreviewMCQForm {
    display: flex;
    flex-direction: row;
    padding: 8px;
    margin: 8px 5px;
    border: 2px solid white;
    background-color: #73b2d0;
    border-radius: 5px;
    height: 60px;
}

div.btn-floating.newMCQForm {
    display: flex;
    margin: auto 5px auto auto;
}

p.addQuestionButtonLabelNewMcqForm {
    display: flex;
    margin: auto auto auto 0px;
}

@media (max-width: 480px) {
    div.flexRow.NewMCQForm {
        min-width: initial;
    }

    textArea.MCQFormQuestionText {
        display: none;
    }

    textArea.MCQFormQuestionTextMobile {
        font-size: 1.4rem;
        padding: 5px 21px;
        text-align: center;
        display: flex;
        color: white;
        width: 100%;
    }

    div.mcqQuestionContainerMobile {
        display: flex;
    }


}