h1.dashboardElementText {
    font-size: 2.5rem;
    display: flex;
    margin: 10px auto 10px 5px;
    height: 40px;
}

svg.rc-progress-line {
    height: 10px;
    width: 90%;
    display: flex;
    margin: 10px auto 0px auto
}

p.progressLabel {
    font-size: 1rem;
    color:  #525174;
    margin: 0px 0px 0px auto;
}


/* h1.dashboardElementText::after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 0px;
    display: block;
} */