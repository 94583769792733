div.mathsQuestionPreviewContainer {
    width: 100%;
    display: inline;
}

p.mathsQuestionText {
    display: inline;
    color: white;
}

div.mathsAnswerContainer {
    width: 100%;
    display: inline;
}

p.mathsAnswerText {
    display: inline;
    color: black;
    margin: auto auto auto 0px !important
}

#answers li p {
    display: inline;
}


div.mathsExpositionPreviewContainer {
    display: inline;
}

p.mathsExpositionText {
    display: inline;
    color: black
}

//Flashcard form
p.mathsFlashcardText {
    display: inline;
    color: black;
    padding: 10px
}

p.mathsFlashcardTextPicture {
    display: inline;
    color: black;
}


//Editor

div.editorContainer {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    margin: auto;
    flex-direction: column;
    padding: 10px;
    width: 80vw;
    height: 80vh;
}

div.editorCloseContainer {
    display: flex;
    margin: 0px 0px 0px auto
}

i.material-icons.closeEditor {
    display: flex;
    margin: auto auto 0px 5px;
}

div.editorTopHalf {
    display: flex;
    flex-direction: column;
    height: 30%;
    border: 1px solid black;
    margin: 10px;
    padding: 10px;
}

div.editorBottomHalf {
    display: flex;
    flex-direction: row;
    height: 100%;
}

div.editorBottom50 {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    margin: 10px;
    padding: 10px;
    width: 50%;
}

//Element editor

div.elementEditorEditableContainer {
    border: dashed 1px grey;
    border-radius: 5px;
    margin: 0px auto 10px auto;
    height: 50%;
    width: 90%;
}