div.btn-floating.closePopupFlagStudentList {
    display: flex;
    margin: 0px 0px 0px auto;
}

div.popupFlagStudentList{
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    margin: auto;
    flex-direction: column;
    width: 85%;
    padding: 20px 30px 30px 30px;
}

h6.popupFlagStudentListTitle {
    display: flex;
    margin: 0px auto 10px auto;
}

div.flagStudentListNameContainer {
    display: flex;
    background-color: #73b2d0;
    border-radius: 5px;
    padding: 5px;
    margin: 2px
}

p.flagStudentListText {
    display: flex;
    font-size: 1.2rem;
    font-weight: 550;
    color: white;
    margin: 0px auto auto auto
}