div.signOutButton {
    display: none;
    margin: 40px auto;
    background-color: #525174;
    padding: 20px 10px;
    max-width: 200px;
    border-radius: 10px;
}

div.signOutButton.message {
    display: none;
}

div.signOutButton.message.false {
    display: flex;
    margin: 40px auto;
    background-color: #525174;
    padding: 20px 10px;
    max-width: 200px;
    border-radius: 10px;

    &:hover {
        transform: scale(1.02);
        cursor: pointer;
    }
}

div.signOutButton.message.true {
    opacity: 0.3;
    display: flex;
    margin: 40px auto;
    background-color: #525174;
    padding: 20px 10px;
    max-width: 200px;
    border-radius: 10px;
}

i.material-icons.signOutButton {
    display: flex;
    margin: auto auto auto 0px;
    color: white
}

p.signOutButtonText {
    font-size: 1.3rem;
    display: flex;
    margin: auto 10px auto auto;
    color: white;
    font-weight: 600;
}

@media (max-width: 480px) {
    div.signOutButton {
        display: flex;
    }
}