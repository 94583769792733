div.activeClassContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    border: grey solid 1px;
    border-radius: 5px;
    margin: 10px auto auto auto;
    padding: 10px;
}

div.myClassesContainer {
    display: flex;
    flex-direction: column;
    margin: 0px auto auto auto
}

div.flexRowManageClasses {
    display: flex;
    flex-direction: row;
}


div.flexColumnTenTop {
    display: flex;
    flex-direction: column;
    margin-top: 10px
}

div.threeQuartersWidthFlex {
    display: flex;
    flex-direction: column;
    width: 75%;
}


div.classContainer1 {
    height: 200px;
    display: flex;
    flex-direction: column;
    width: 96%;
    padding: 0px 10px;
    overflow: auto;
    margin: 10px;
    border-radius: 5px
}

p.myClasses {
    display: flex;
    margin: auto 5px auto auto;
    height: 20px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
}

div.eightyFlex {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 5px auto;
}

p.classNameManageClasses {
    display:flex;
    color: white;
    font-weight: 600;
    font-size: 1.1rem
}

div.classNameContainerManageClasses {
    background-color: #348aa7;
    padding: 0px 20px;
    border-radius: 10px;
    margin: 2px 0px;
    display: flex;
    flex-direction: row;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;

    &:hover {
        transform: scale(1.01);
        cursor: pointer;
    }
}

p.flexCentre {
    display: flex;
    margin: auto;
}

div.flexRowClasses {
    display: flex;
    flex-direction: row;
    margin: auto 5px auto auto
}

div.flexRowButtons {
    display: flex;
    flex-direction: row;
    margin: auto 5px;
}

p.signupTextManageClasses {
    color: white;
    font-weight: 600;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    &:hover {
        transform: scale(1.05);
        cursor: pointer;
    }
}

i.material-icons.flexAuto {
    display: flex;
    margin: auto;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;

    &:hover {
        transform: scale(1.2);
        cursor: pointer;
    }
}



//Add class button

div.addClass{
    display: flex;
    flex-direction: row;
    margin: auto auto auto 5px;
    height: 50px;
    width: 140px;
    background-color: #348aa7;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

div.flexAuto {
    display: flex;
    margin: auto;
}

div.addClass:hover {
    transform: scale(1.02);
    cursor: pointer;
}

p.addClass {
    display: flex;
    margin: auto
}

i.material-icons.addManageClasses {
    display: none
}

div.btn-floating.flex {
    display: flex;
    margin: auto
}

// Students

div.flexRowViewClass {
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-radius: 5px;
}

p.viewClass {
    display: flex;
    margin: auto;
    font-weight: 600;
    font-size: 1.2rem;
}

select.selectSortManageClasses {
    display: flex;
    margin: auto 10px auto auto;
    width: 70%
}

div.studentContainerManageClasses {
    display: flex;
    background-color: #348aa7;
    color: white;
    border-radius: 5px;
    margin: 0px auto 5px auto;
    padding: 10px 5px
}

p.studentManageClasses {
    display: flex;
    margin: auto auto auto 5px
}

select.studentSelectSort {
    display: flex;
    width: 60%;
}

i.material-icons.studentIcon {
    display: flex;
    margin: auto auto auto 5px;
    width: 30px;
}

div.flex {
    display: flex
}


@media (max-width: 480px) {
    div.mobileContainerManageClasses {
        display: flex;
        height: 90vh;
        overflow: auto;
    }

    div.flexRowManageClasses {
        margin: 5px 0px
    }

    div.activeClassContainer {
        padding: 2px;
        width: 100%
    }

    div.classContainer1 {
        width: 100%; 
        padding: 0px 5px;
        margin: auto
    }

    p.myClasses {
        margin: 0px 5px auto auto;
        color: grey
    }

    div.addClass {
        display: none
    }

    div.eightyFlex {
        height: 100%;
        margin: auto
    }

    i.material-icons.addManageClasses {
        display: flex;
        margin: auto auto auto 0px;
        color: grey
    }
}