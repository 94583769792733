div.popupStudentFeed {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    margin: auto;
    flex-direction: column;
    height: 90vh;
    min-width: 1000px;
    padding: 20px 30px 30px 30px;
}

div.flexRowStudentFeed {
    display: flex;
    flex-direction: row;
    height: 90%;
}

div.studentFeedContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: #bfbfbf solid 1px;
    border-radius: 5px;
    background-color: white;
    width: 60%;
    height: 100%;
    margin: auto auto auto 10px;
        
        &.centre {
            margin: auto
        }
}

div.flexRowStudentDataContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
}

div.classContainerStudentDataContainer {
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%
  }

div.studentContainerTeacherDataContainer {
    margin: auto 5px;
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 10px;
    border: #bfbfbf solid 1px;
    border-radius: 5px;
    height: 100%
}

i.material-icons.closeStudentFeed {
    display:none
}

p.feedTitleStudentFeed {
    display: flex;
    color: rgb(77, 77, 77);
    font-size: 1.2rem;
    font-weight: 600;
    margin: auto 
}

@media (max-width: 480px) {
    div.btn-floating.closePopup.studentFeed {
        display: none
    }

    i.material-icons.closeStudentFeed {
        display: inline-block
    }

    div.popupStudentFeed {
        width: 95%;
        min-width: initial;
        padding: initial
    }

    div.studentFeedContainer {
        width: 100%;
        border: initial
    }

    p.feedTitleStudentFeed {
        color: white;
    }

    div.flexRow.studentFeed {
        flex-direction: row;
    }

}