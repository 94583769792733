div.homeContainertrue {
    display: none
}

div.homeContainerfalse {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

div.imgContainer {
    padding: 20px;
    display: flex;
    height: auto;
    max-width: 700px;
}

img.profile {
    align-self: center;
    flex: 0 0 auto;
    display: block;
    max-width: 100%;
    height: auto;
    /* border-radius: 1000px; */
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

div.row.home {
    margin-bottom: 0px;
}

div.flexColumn.home {
    margin: auto;
}

div.flexColumn.home.tutor {
    margin: auto;
}

div.flexColumn.home.tutor {
    padding: 20px
}

div.flexColumn.home.transparent {
    padding: 20px
}

div.flexColumn.home.white {
    margin: auto;
    padding: 100px;
    height: 100%
}

div.container.section.home {
    padding-bottom: 0;
}

div.container.section.home.tutor {
    padding-bottom: 0;
    width: 90%;
}

div.bannerBackground {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fbanner.png?alt=media&token=e6b9feb0-3df3-4a26-829f-f5cda36833e6");
    background-repeat: no-repeat;
    background-size: cover;
}

div.bannerBackgroundReverse {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2Fbannerreverse.png?alt=media&token=bbea63ff-e4b9-4a33-8193-760ea051670f");
    background-repeat: no-repeat;
    background-size: cover;
}

img.responsive-img.trustpilot {
    height: 25px;
    display: flex;
    margin: 5px 10px auto auto;
}

img.responsive-img.trustpilotstar {
    height: 25px;
    display: flex;
    margin: auto auto auto 10px;
}

img.responsive-img.books {
    display: none;

}

div.flexRow.trustpilot {
    margin: auto auto 0px auto
}

div.flexRow.tutor {
    border-radius: 15px;
}

p.trustpilotText {
    margin: auto
}

img.linkedin {
    margin: auto 5px auto 0px;
    height: 24px;
    width: 24px;

    &:hover {
        transform: scale(1.02);
        cursor: pointer;
    }
}

i.material-icons.tutorEmail {
    display: flex;
    margin: auto 5px auto 0px;
}

span.trustpilotBold {
    font-weight: 600;
    margin: auto 5px;

}

h2.headerBold.banner {
    font-weight: 800;
    color: rgb(65, 65, 65);
    margin: auto;
    margin-bottom: 0px;
}

h2.headerBold.banner.tutor {
    color: #525174;
}


h4.grey-text.banner {
    display: flex;
    margin: auto;
    padding: 0px 10%;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

h5.grey-text.text-darken-2.center.hundredschools {
    margin-top: 0px;
    margin-bottom: 0px;
}

div.tryQuiz {
    display: flex;
    flex-direction: row;
    background-color: #513b56;
    width: 250px;
    padding: 10px 5px 10px 20px;
    border-radius: 40px;
    margin: 20px auto 5px auto
}

h4.grey-text.text-darken-2.offset-l1.howitworks {
    margin-left: 20px;
    margin-top: 0px;
}

a.btn-floating.pulse {
    margin: auto;
    background-color: #f9c231;
}

a.trialButtonText {
    margin-right: 20px;
    font-size: 2rem;
    display: flex;
    margin: auto;
    color: white;
}

div.card-image {
    display: flex;
    max-width: 80%;
    margin: auto;
}

img.card-image {
    display: flex;
    margin: auto
}

/* SIGNUP BANNER */
div.trialBannerContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: #5dd39e;
    border-radius: 10px;
    padding: 20px 20px;
    width: 70%;
}

div.trialBannerTextContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 90%
}

div.trialBannerTextContainerTop {
    display: flex;
    flex-direction: row;
}

h4.trialBanner {
    text-align: center;
    display: flex;
    margin: auto;
    color: rgb(255, 255, 255);
}

p.trialText {
    display: flex;
    margin: 10px auto auto auto;
    text-align: center;
}


@media (max-width: 480px) {
    img.responsive-img.books {
        display: flex;
        margin-top: -20px;
    }

    div.bannerBackground {
        background-image: none
    }

    div.bannerBackgroundReverse {
        background-image: none
    }

    h4.grey-text.banner {
        padding: 0;
        margin-bottom: 10px;
    }

    div.tryQuiz {
        margin: auto;
    }

    div.flexRow.trustpilot {
        display: none;
    }

}