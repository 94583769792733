.menu ul{
    list-style: none;
}
.menu li{
    display: inline;
}


div.popupFeedback {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    margin: auto;
    flex-direction: column;
    padding: 10px;
    width: 400px;
    height: 500px;
}
div.fancy-btn.feedbackSubmit {
    display: inline-block;
    width: 280px;
    background-color: black;
    border-radius: 0px;
    margin-bottom: 10px;

    &:hover {
        background-color: grey;
        cursor: pointer;
    }
}

div.fancy-btn.feedbackSubmit.active {
    display: inline-block;
    width: 300px;
    background-color: blue;
    cursor: pointer;
}
div.feedbackTextAreaContainer {
    width: 300px;
    margin: auto;
}
div.feedbackTextAreaContainer.active {
    display: inline-block;
}
