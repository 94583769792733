label.select-label {
    display: initial;
    position: initial;
    font-size: 1.2rem;
}

div.flexColumnTeacherData {
    display: flex;
    flex-direction: column;
}

p.noStudentsTextPlaceholder {
    text-align: center;
}

div.btn.centre.onboarding {
    max-width: 300px;
    display: flex;
    margin: auto;
}

p.onboarding {
    display: flex;
    margin: auto
}

div.classviewPlaceholder {
    border: grey 1px dashed;
    padding: 20px;
    height: 300px;
    margin-bottom: 10px;
}

div.teacherDataMobileDiv {
    height: initial
}

@media (max-width: 480px) {
    div.teacherDataMobileDiv {
        height: 10vh
    }

    div.flexColumnTeacherData {
        height: 100%
    }

    div.classviewPlaceholder {
        height: 100%
    }
}