div.popupSettings {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    margin: auto;
    flex-direction: column;
    padding: 10px;
    width: 300px;
}

div.btn-floating.closePopupSettings {
    display: flex;
    margin: 0px 0px 0px auto;
}

p.settingsTitle {
    font-weight: 600;
    font-size: 1.3rem
}

div.settingsLogoutButton {
    display: flex;
    background-color: #525174;
    width: 150px;
    margin: 20px auto;
    padding: 10px;
    border-radius: 10px;
}

i.material-icons.logout {
    display: flex;
    margin: auto 5px auto auto;
    color: white
}

p.logoutText {
    display: flex;
    margin: auto auto auto 5px;
    color: white;
    font-weight: 600;
}

label.weeklysummaryLabel {
    position: relative;
    margin-left: 20px;
}