div.gamificationContainer.transparent {
    flex-direction: column;
}

div.flaggedBy {
    display: flex;
    margin-top: 6px;
}

div.flaggedBy:hover {
    cursor: pointer;
} 

div.flagContainer {
    display: flex;
    margin-top: 6px;
    cursor: pointer;
}

div.flagContainer.hidden {
    display: none;
}

div.flagResolved {
    display: none;
}

div.flagResolved.active {
    display: flex;
    margin-top: 6px;
    cursor: text;
}

div.flagResolved.active.teacher {
    cursor: pointer;
}

div.bookmarkContainer {
    display: flex;
    margin-top: 6px;
    cursor: pointer;
}

div.bookmarkContainer.hidden {
    display: none;
}

div.bookmarkResolved {
    display: none;
}

div.bookmarkResolved.active {
    display: flex;
    margin-top: 6px;
    cursor: pointer;
}
/* Popup level up dynamics */
div.endQuizPopup.hidden {
    display: none;
}

div.levelUpPopup {
    display: none;
}

div.levelUpPopup.active {
    display: block;
}

div.xpBarMcq {
    display: flex;
    width: 75px;
    height: 75px;
}

/* XP Bar customization */
.xpBarMcq.hidden {
    display: none;
}

/* Adding gamification */
div.questionFlashcard {
    display: block;
}

div.xpStreakMcq {
    display: flex;
    flex-direction: column;
}

div.gamificationTopContainer {
    display: flex;
    flex-direction: row;
    margin: 0px auto auto auto;
    width: 100%;
}

div.fullWidth {
    width: 100%
}

div.flexMaxWidth{
    display: flex;
    max-width: 400px;
    margin: auto auto 10px 0px;
}

div.flexRow {
    display: flex;
    flex-direction: row;
}

div.gamificationContainer{
    display: flex;
    flex-direction: row;
    margin: auto;
    padding: 0px 5px 0px 5px;
    border-radius: 5px;
}

a.gamificationContainer {
    display: flex;
    color: #525174;
}

#question h4.gamification {
    margin-block-start: 0px;
    margin-block-end: 0px;
    right: 0px;
    background-color: white;
    display: flex;
    margin: auto;
    border: white 2px solid;
    border-radius: 5px;
    color: #73b2d0;
    position: relative;
    padding: 5px 10px;
    font-size: 20px;
    font-weight: 600;
}

h4.gamification {
    display: flex;
    margin: 5px auto;
    border-radius: 5px;
    color: white;
    position: relative;
    padding: 5px 10px;
    font-size: 20px;
    font-weight: 600;
}

.btn-floating.coin.pulse {
    width: 25px;
    height: 25px;
    display: flex;
    margin: auto 8px auto auto;
    background-color: #f9c231;
}

.btn-floating i.material-icons.coin {
    display: flex;
    margin: auto;
    width: auto;
    top: -8px;
    left: 1px;
    position: relative;
}

i.material-icons.fire {
    display: flex;
    margin: auto;
    width: auto;
    position: relative;
    color: red;
    font-size: 20px;
}

#question p.coin {
    display: flex;
    margin: auto;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 1rem;
}

p.coin {
    display: flex;
    margin: auto;
    padding-left: 5px;
    padding-right: 0px;
    
}

p.mcqQuestion {
    color: white;
    padding: 10px;
    margin: 0px auto auto auto;
    text-align: center;
    width: 100%;
    font-size: 20px;
    color: white;
}

p.mcqQuestionTrial {
    color: white;
    padding: 10px;
    margin: 0px auto auto auto;
    text-align: center;
    width: 100%;
    font-size: 20px;
    color: white;
}

p.mcqQuestionMobile{
    display: none;
    color: white;
    padding: 10px;
    text-align: center;
    width: 100%;
}

div.mcqQuestionContainer {
    display: flex;
    margin: auto;
    flex-direction: column;
}

div.mcqQuestionContainerMobile {
    display: none;
    margin: auto;
    flex-direction: column;
}

/* Graph template specific formatting */

.topContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: #73b2d0;
}

div.question {
    display: flex;
    flex-direction: column;
    color: white;
    padding: 10px;
    text-align: center;
    width: 100%
}

div.questionContainer {
    
    color: white;
    padding: 5px 10px 10px 10px;
    text-align: center;
    width: 100%
}

#image {
    display: flex;
    width: 300px;
    height: 300px;
    margin: 10px;
}

.graph {
    width: 300px;
    height: 300px;
    display: flex
}

div.coinLabel {
    display: flex;
}

div.coinLabel span {
    display: flex;
    margin: auto 5px auto auto;
}


/*  */
div.col-lg-10.col-lg-offset-1 {
    margin-top: 5px;
}


#question h4{
    background-color: #fff;
    color: #333333;
    display:inline-block;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 600;
}

#question p{
    font-size: 20px;
    padding-left: 50px;
    padding-right: 50px;
}

#answers ul{
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#answers li {
    background-color: #fff;
    border: 2px solid  #73b2d0;
    border-radius: 5px;
    min-height: 70px;
    width: 48%;
    display: flex;
    margin-bottom: 30px;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    cursor: pointer;
}

// #answers li span {
//     background-color: #73b2d0;
//     color: #fff;
//     font-size: 30px;
//     flex: 75px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     transition: background-color .3s ease;
// }

span.spanLetter {
    background-color: #73b2d0;
    color: #fff;
    font-size: 30px;
    flex: 75px;
    display: flex;
    height: 100%;
    min-width: 50px;
    justify-content: center;
    align-items: center;
    transition: background-color .3s ease;
}

#answers li p {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    flex: calc(100% - 75px);
    margin: auto 20px;
    transition: color .3s ease;
}

#answers li:after{
    display: block;
}

#answers li:hover {
    transform: scale(1.03);
}

#answers li.right {
    border-color: #1ea55b;
    color: #1ea55b;
}

// #answers li.right span {
//     background-color: #1ea55b;
// }

#answers li.right p {
    color: #1ea55b;
}

li.right span.spanLetter {
    background-color: #1ea55b;
}


#answers li.wrong {
    border-color: #dc0a0a;
    color: #dc0a0a;
}

// #answers li.wrong span {
//     background-color: #dc0a0a;
// }

li.wrong span.spanLetter {
    background-color: #dc0a0a;
}

#answers li.wrong p {
    color: #dc0a0a;
}

#submit {
    text-align: center;
    margin: 20px 0;
}

.exposition-container {
    width: 100%;
    display: flex;
    margin: auto;
    border: 2px solid #0084da;
    border-radius: 5px;
    padding: 5px
}

.exposition {
    width: 100%;
    color: black;
    margin: auto;
}

.exposition p {
    margin: 0px
}

span.incorrect {
    color: red;
    display: inline-flex;
}

span.correct {
    color: green;
    display: inline-flex;
}

span.bold {
    font-weight: bold;
    text-decoration: underline;
}

.fancy-btn {
    border: 2px solid #0b0e21;
    border-radius: 500px;
    background-color: #0b0e21;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 75px;
    margin: 0 auto;
    text-transform: uppercase;
    transition: color .2s ease, background-color .2s ease;
}

.fancy-btn:hover {
    background-color: #fff;
    color: #0b0e21;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

div.container.levelUp {
    display: flex;
    margin: auto;
}

div.col-md-8.col-md-offset-2.levelUp {
    display: flex;
    margin: auto;
}

.popup {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.popup h1 {
    background-color: #0094da;
    color: white;
    border-bottom: 1px solid #ccc;
    padding: 20px;
    margin-top: 0; 
}

.popup p {
    font-size: 18px;
    letter-spacing: 1px;
    margin: 20px 10% 0;
}

.popup .fancy-btn{
    margin: 20px 10px 20px 10px;
    cursor: pointer;
}

/* PopupNeedHelp */
div.CCC.maxWidth {
    display: flex;
    margin: auto;
}

div.popupNeedHelp {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    margin: auto;
    flex-direction: column;
    padding: 10px;
    width: 300px;
    height: 200px;
}

div.btn-floating.closePopup {
    display: flex;
    margin: 0px 0px auto auto
}

.fancy-btn.needHelp {
    display: flex;
    padding: 10px 35px;
}

i.material-icons.needHelp {
    display: flex;
    margin: auto;
    font-size: 30px;
}

h5.needHelp {
    display: flex;
    margin: 0px auto auto auto;
}

p.needHelp {
    margin: auto;
}

/* Footer */

footer {
    background-color: #0b0e21;
    color: white;
    letter-spacing: 1px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    text-align: center;
}

footer p {
    margin: 0;
}

footer small {
    margin-bottom: 10px;
}

footer span {
    color: crimson;
}

.bigButton {
    width: 80%;
    background-color: #0094da;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    margin: auto auto 15px auto;

}

.bigButton .bigButtonText{    
    display: flex;
    margin: auto auto auto 30px;
    color: white;
}

.bigButton .openArrow{    
    display: flex;
    margin: auto auto auto 5px;
    font-size: 30px;
    color: white;
}

.categoryContainers {
    width: 50%;
    margin: auto auto auto 200px;
    font-size: 30px;
    color: white;
    display: flex;
}

.gapsQuizContainer {
    width: 80%;
    min-height: 400px;
    background-color: #0094da;
    display: flex;
    margin: auto;
    border-radius: 5px;
}

.gapsQuizContainer p {
    color: white;
    font-size: 1.8rem;
    word-spacing: 0.5rem;
    padding: 20px;
}

.gapsQuizContainer span {
    color: black;
    background-color: white;
    border-radius: 3px;
}

@media (max-width: 768px) {
    #question p {
        padding-left: 10px;
        padding-right: 10px;
    }
    
    #answers li {
        width: 100%;
    }
    
    #submit button {
        width: 100%;
    }
}

/* alternative streak that only shows up on mobile */
div.streakMobile {
    display: none;
}
i.material-icons-fire-mobile {
    margin: auto;
}

/* QuizHeader */
div.quizHeaderMobile {
    display: none
}

p.quizHeaderMobileText {
    display: flex;
    margin: auto auto auto 5px;
    font-size: 1.2rem;
}

div.tagQuestionMobileContainer {
    width: 100%;
    max-width: 300px;
    margin: auto auto auto 0px;
    display: flex
}

div.tagButtonContainer {
    display: flex;
    margin: auto;

    &:hover {
        transform: scale(1.05);
        cursor: pointer;
    }
}

p.tagButtonText {
    margin: auto auto auto 5px;
    color: grey
}

@media (max-width: 480px) {
    /* mobileTagQuestion */
    div.tagQuestionMobileContainer {
        margin-top: 10px;
        display: flex;
    }
    
    /*  */
    div.quizHeaderMobile {
        display: flex;
    }

    div.mcqQuestionContainer {
        display: none;
    }

    div.mcqQuestionContainer.Mobile {
        display: flex;
    }

    div.gamificationTopContainer { 
        display: none;
    }
    .topContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-top: 10px
    }

    p.mcqQuestion{
        display: none;
    }

    div.question {
        padding: 0px 10px 10px 10px
    }

    div.questionContainer {
        padding: 0px 10px 10px 10px;

    }

    div.mcqQuestionContainer {
        margin-top: 50px;
    }

    div.mcqQuestionContainerMobile {
        display: flex;
    }

    div.mcqQuestionContainer.Trial {
        margin-top: auto;
    }

    div.mcqQuestionContainerTrial {
        display: flex;
        margin: auto;
        flex-direction: column;
    }

    div.mcqQuestionContainer.Trial.Mobile {
        display: flex
    }

    p.mcqQuestionMobile{
        font-size: 1.4rem;
        padding: 5px 21px;
        text-align: center;
        display: inline;
        color: white;
        width: 100%;
    }


    #answers ul {
        margin-top: 5px;
    }

    #answers li {
        margin-bottom: 5px;
    }

    #question h4.gamification {
        right: 0px
    }

    h4.gamification {
        right: 0px;
    }

    div.flexMaxWidth{
        display: flex;
        max-width: 300px;
        margin: auto auto 0px 0px;
    }

    div.streakMobile{
        display: flex;
    }

    /* div.blueStreakContainerMcq {
        display: none;
    } */

    div.gamificationContainer.transparent {
        margin: auto auto auto 5px;
        flex-direction: column;
    }
    
    #image {
        display: flex;
        width: 300px;
        height: 300px;
        margin: auto;
    }
    
    footer {
        padding: 5px;
    }
    
    footer small {
        display: none;
    }

    div.flexRow {
        flex-direction: column;
    }

    div.avatarInfoContainer {
        flex-direction: column;
    }
}

.formContainer {
    width: 80%;
    min-height: 40px;
    background-color: #0094da;
    margin: 2px auto 2px auto;
    border-radius: 5px;
    color: black;
    font-size: 1.8rem;
    word-spacing: 0.5rem;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.questionTitle {
    margin: 10px auto 10px auto;
    padding: 10px 30px 10px;
}

.formQuestion {
    width: 95%;
    min-height: 150px;
    margin: 0px auto 0px auto;
    border-radius: 5px;
    display: flex;
    padding: 10px;
}

.answerContainer {
    width: 95%;
    padding: 10px;
    margin: 5px auto 5px auto;
    display: flex;
    flex-direction: row;
    border-style: solid;
}

.formContainerTop {
    width: 100%;
    margin: 0px auto 10px auto;
}

.answerContainerleft {
    width: 70%;
}

.answerContainerright {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.correctAnswer {
    margin: auto;
}

.formAnswer {
    width: 100%;
    border-radius: 5px;
    display:inline-block;
    margin: auto;
}

.formExposition {
    width: 100%;
    min-height: 100px;
    border-radius: 5px;
    display:inline-block;
    margin: auto;
}

.correctButton {
    display: flex;
    margin: auto !important;
}

/* Avatar animations */
.fade-in {
    height: 300px;
    width: 300px;
    position: absolute;
    animation: fadeIn ease 6s 2s forwards;
    -webkit-animation: fadeIn ease 6s 2s forwards;
    -moz-animation: fadeIn ease 6s 2s forwards;
    -o-animation: fadeIn ease 6s 2s forwards;
    -ms-animation: fadeIn ease 6s 2s forwards;
    }
    @keyframes fadeIn {
    0% {opacity:0; transform: scale(0)}
    100% {opacity:1; transform: scale(1);}
    }
    
    @-moz-keyframes fadeIn {
    0% {opacity:0; transform: scale(0)}
    100% {opacity:1; transform: scale(1);}
    }
    
    @-webkit-keyframes fadeIn {
    0% {opacity:0; transform: scale(0)}
    100% {opacity:1; transform: scale(1);}
    }
    
    @-o-keyframes fadeIn {
    0% {opacity:0; transform: scale(0)}
    100% {opacity:1; transform: scale(1);}
    }
    
    @-ms-keyframes fadeIn {
    0% {opacity:0; transform: scale(0)}
    100% {opacity:1; transform: scale(1);}
    }

.fade-inQuick {
    height: 300px;
    width: 300px;
    position: absolute;
    animation: fadeIn ease 8s forwards;
    -webkit-animation: fadeIn ease 8s forwards;
    -moz-animation: fadeIn ease 8s forwards;
    -o-animation: fadeIn ease 8s forwards;
    -ms-animation: fadeIn ease 8s forwards;
    }
    @keyframes fadeIn {
    0% {opacity:0; transform: scale(0)}
    100% {opacity:1; transform: scale(1);}
    }
        
    @-moz-keyframes fadeIn {
    0% {opacity:0; transform: scale(0)}
    100% {opacity:1; transform: scale(1);}
    }
        
    @-webkit-keyframes fadeIn {
    0% {opacity:0; transform: scale(0)}
    100% {opacity:1; transform: scale(1);}
    }
        
    @-o-keyframes fadeIn {
    0% {opacity:0; transform: scale(0)}
    100% {opacity:1; transform: scale(1);}
    }
        
    @-ms-keyframes fadeIn {
    0% {opacity:0; transform: scale(0)}
    100% {opacity:1; transform: scale(1);}
    }
    
.fade-out {
    height: 300px;
    width: 300px;
    position: absolute;
    animation: fadeOut ease 8s forwards;
    -webkit-animation: fadeOut ease 8s forwards;
    -moz-animation: fadeOut ease 8s forwards;
    -o-animation: fadeOut ease 8s forwards;
    -ms-animation: fadeOut ease 8s forwards;
    }
    @keyframes fadeOut {
    0% {opacity:1; transform: scale(1);}
    100% {opacity:0; transform: scale(0);}
    }
        
    @-moz-keyframes fadeOut {
    0% {opacity:1; transform: scale(1);}
    100% {opacity:0; transform: scale(0)}
    }
        
    @-webkit-keyframes fadeOut {
    0% {opacity:1; transform: scale(1);}
    100% {opacity:0; transform: scale(0)}
    }
        
    @-o-keyframes fadeOut {
    0% {opacity:1; transform: scale(1);}
    100% {opacity:0; transform: scale(0)}
    }
        
    @-ms-keyframes fadeOut {
    0% {opacity:1; transform: scale(1);}
    100% {opacity:0; transform: scale(0)}
    }

div.avatarContainer {
    height: 300px;
    width: 300px;
    margin: 50px auto;
}
div.avatarContainer.final {
    margin: 50px 10px 50px 20px;
}
.responsive-img.prevAvatar {
    height: 300px;
}
.responsive-img.newAvatar {
    height: 300px;
}
div.avatarInfoContainer {
    display: flex;
    max-width: 700px;
    margin: auto;
}
p.evolutionMessage {
    text-align: left;
    color: gray;
    font-weight: 600;
    font-size: 1.5rem;
}
p.economistMessage {
    margin-left: 0px;
    text-align: left;
}
div.avatarInfo {
    display: none;
}
div.avatarInfo.active {
    display: flex;
    flex-direction: column;
    padding: 10px
}
li.economistInfo {
    display: flex;
}
ul.economistList {
    text-align: left;
    max-width: 90%;
}
div.popup.endQuiz {
    max-width: 900px;
    margin: 10px auto;
}
button.fancy-btn.endQuiz {
    margin: 20px auto auto auto;
}