div.createQuizPopup {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    margin: auto;
    flex-direction: column;
    padding: 10px;
    width: 300px;
    min-height: 200px;
}

div.btn-floating {
    overflow: initial;
}

div.createQuizButton {
    display: flex;
    margin: 10px auto;
    background-color: black;
    width: 200px;
    height: 50px;
    border-radius: 500px;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
}

div.createQuizButton:hover {
    background-color: #fff;
    color: #0b0e21;
    border: black solid 2px;
    cursor: pointer;
}

a.createQuizButton {
    display: flex;
    margin: 10px auto;
    background-color: black;
    width: 200px;
    border-radius: 500px;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 5px
}

a.createQuizButton:hover {
    background-color: #fff;
    color: #0b0e21;
    border: black solid 2px;
    cursor: pointer;
}

select.quizDropdown {
    display: flex;
    margin: 15px auto;
    width: 90%;
    background-color: rgb(245, 245, 245);
    border-color: #9e9e9e;
}

p.noBookmarks{
    margin: auto;
    font-weight: 600;
    font-size: 1.2rem;
    color: #6e6e79;
    padding: 10px;
}