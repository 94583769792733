div.Collapsible {
    &:hover {
        cursor: pointer;
    }
}

a.resourceButton {
    display: flex;
    margin: auto auto auto 10px;
    background-color: #525174;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px;
    border-radius: 10px;
    min-width: 200px;

}

p.resourceButtonTitle {
    display: flex;
    margin: auto
}

h3.econMathsTitle{
    display: flex;
    margin: 20px auto auto 0px;
    width: 50%;
    color: #525174;
    font-weight: 600;
}

p.econMathsBold {
    font-weight: 600;
}

a.worksheetButton {
    display: flex;
    color: #525174;
    margin: auto 10px auto auto
}

div.lessonContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px auto;
}

div.videoContainer {
    margin: 10px auto;
    position: relative;
    overflow: hidden;
    padding-top: 56%;
    width: 100%;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

i.material-icons.worksheetButton {
    display: flex;
    margin: auto 10px auto auto
}

@media (max-width: 480px) {
    p.quizLandingThemeNameTextQuizDash {
        font-size: 1rem;
    }

    p.quizLandingCreateQuizDash {
        font-size: 1rem;
    }

    a.resourceButton {
        display: flex;
        margin: 10px auto;
        background-color: #525174;
        color: white;
        font-size: 1rem;
        font-weight: 600;
        padding: 10px;
        border-radius: 10px;
        min-width: 200px;
    
    }

}