.popupManageClasses {    
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

div.ninetyPadded {
    display: flex;
    margin: auto;
    width: 90%;
    padding: 10px
}


//popup
div.closePopupManageClasses {
    display: flex;;
    width: 30px;
    margin: 0px 0px auto auto;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
}

div.closePopupManageClasses:hover {
    transform: scale(1.2);
    cursor: pointer;
}

i.material-icons.green-text.manageClasses {
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
}

i.material-icons.green-text.manageClasses:hover {
    transform: scale(1.2);
    cursor: pointer;
}

//Warning popup

div.flexRowWarning {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

div.removeButton {
    display: flex;
    margin: auto 10px auto auto;
    background-color: black;
    width: 125px;
    height: 50px;
    border-radius: 500px;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
}

div.removeButton:hover {
    background-color: #fff;
    color: #0b0e21;
    border: black solid 2px;
    cursor: pointer;
}

div.cancelButton {
    display: flex;
    margin: auto auto auto 10px;
    background-color: black;
    width: 125px;
    height: 50px;
    border-radius: 500px;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
}

div.cancelButton:hover {
    background-color: #fff;
    color: #0b0e21;
    border: black solid 2px;
    cursor: pointer;
}

p.removeButton {
    display: flex;
    margin: auto
}

p.cancelButton {
    display: flex;
    margin: auto
}


//Signup popup
p.signUpTitle {
    margin: 10px auto 10px auto;
    font-size: 1.2rem;
    font-weight: 600;
}

p.classLinkText {
    margin: auto 10px auto auto;
    color: blue;
    font-weight: 600;
}

div.copyToClipboard {
    font-size: 1rem;
    display: flex;
    margin: auto auto auto 10px;
    color: white;
    border-radius: 5px;
    background-color: #525174;
    vertical-align: middle;
    padding: 0px 10px;
    font-weight: 600;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;

    &:hover {
        transform: scale(1.05);
        cursor: pointer;
    }
}

i.material-icons.copyToClipboard {
    margin: auto 5px
}

p.signUpCopyInstruction {
    margin: auto auto 20px auto;
    color: #525174;
}