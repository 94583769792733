[type="radio"]:not(:checked), [type="radio"]:checked {
    position: relative;
    opacity: 1;
    margin-right: 10px;
    pointer-events: initial;
    cursor: pointer;
}


[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
    position: absolute;
    opacity: 1;
    pointer-events: initial;
    top: 4px;
}

label.termsLabel {
    position: relative;
    margin-left: 20px;

}

div.input-field.mt-1 {
    margin-top: 5px;
    margin-bottom: 0px;
}

div.input-field.mt-1.bottom {
    margin-bottom: 0px;
}

.studentTeacherRadio {
    font-size: 1rem;
}

label.studentTeacherQuestion {
    font-size: 1rem;
}

div.signUpHeader{
    display: flex;
    flex-direction: row;
}

form.white {
    margin-top: 5px;
    background-color: rgb(245, 245, 245) !important;
}

a.alreadyUser {
    font-size: 1rem;
    display: flex;
    margin: auto auto auto 20px;
    color: white;
    border-radius: 5px;
    background-color: #525174;
    vertical-align: middle;
    padding: 5px 8px
}

select.schoolDropdown {
    display: block;
    background-color: rgb(245, 245, 245);
    border-color: #9e9e9e;
}

label.select-label {
    display: initial;
    position: initial;
}

.get-schools-list {
    color: #03C;
    font-size: 13px;
    cursor: pointer;
}

button.btn.pink.lighten-1.z-depth-0.greyed {
    background-color: grey !important;
}

i.material-icons.refreshButton {
    display: flex;
    margin: auto auto auto 0px;

    &:hover {
        cursor: pointer;
    }
}

//Similar schools popup

div.schoolMenuSchoolContainer {
    background-color: #bce784;
    padding: 5px 0px;
    margin: 10px 5px;
    border-radius: 5px;
    display: flex;

    &:hover {
        cursor: pointer;
    }
}

p.schoolMenuSchoolText {
    font-size: 1.1rem;
    font-weight: 500;
    color:grey;
    display: flex;
    margin: auto;
}