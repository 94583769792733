div.popupNeedHelp.dataLoggingCheck {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    margin: auto;
    flex-direction: column;
    padding: 10px;
    width: 320px;
    height: auto;
}


div.assignButton.dataLoggingCheck {
    height: 50px;
    width: 300px;
    padding: 5px;
    border-radius: 5px;
    background-color: #525174;
    display: flex;
    color: white;
    margin: 5px auto; 
    
    &:hover {
        cursor: pointer;
    }
}

div.twoButtonAssignContainer.dataLoggingCheck {
    display: flex;
    flex-direction: column;
    margin: auto;
}