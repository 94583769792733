div.container.subjectMenu {
    max-width: 300px;
}

div.popupSubjectMenu{
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 10px;
}

div.subjectMenuCloseContainer {
    display: flex;
    margin: auto;
    width: 100%;
}

div.subjectMenuSubjectContainer {
    background-color: #bce784;
    margin: 5px 10px;
    border-radius: 10px;
    display: flex;

    transition: .3s ease;

    &:hover {
        transform: scale(1.005);
        cursor: pointer;
    }
}

i.material-icons.subjectMenuSubjectIcon {
    display: flex;
    margin: auto 10px;
    font-size: 2rem;
}

div.subjectMenuChangeSubjectTextContainer {
    display: flex;
    margin: 0px auto 10px auto
}

p.subjectMenuChangeSubjectText{
    font-size: 1.2rem;
    font-weight: 600;
    color:grey;
    display: flex;
    margin: auto;
    line-height: 1.2rem;
}

p.subjectMenuSubjectName {
    color: #525174;
    display: flex;
    margin: auto auto auto 30px;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2rem;
    
}


i.material-icons.closeSubjectMenu {
    display: flex;
    margin: 0px 10px 0px auto;
    color: grey;
    float: right;
    transition: .3s ease;

    &:hover {
        transform: scale(1.2);
        cursor: pointer;
    }
}

div.feedbackTextAreaContainer.subjectMenu {
    display: flex;
    flex-direction: column;

}

p.subjectMenuChangeSubjectText.improve {
    margin: 10px auto

}

textarea.feedbacktextArea.improve {
    padding: 10px;
    height: 75px;
    border-radius: 5px;
}

div.fancy-btn.feedbackSubmit.improve {
    width: 100px;
    display: flex;
}

div.submitButton.container {
    display: flex;
    margin: auto;
}

div.button.submitImprovement {
    display: flex;
    margin: auto;
    width: 150px;
    height: 50px;
    border-radius: 5px;
    background-color: #525174;
    font-weight: 600;
    font-size: 1.1rem;
    color: white;

    &:hover {
        cursor: pointer;
    }
}

p.needHelp.submit {
    line-height: initial;
}

p.subjectSubmission.bicep {
    display: flex;
    margin: 10px auto 0px auto;
    font-size: 50px;
}

p.subjectSubmission.text{
    color: grey;
    display: flex;
    margin: auto;
    line-height: initial;
    font-size: 20px;
}

@media (max-width: 450px) {
    div.subjectMenuCloseContainer {
        margin: 5px;
    }

    div.subjectMenuSubjectContainer {
        padding: 10px 0px
    }
}