div.CCC.maxWidth.verifyTeacher {
    height: auto;
}

div.popupNeedHelp.verifyTeacher {
    height: auto;
    max-height: 600px
}

h5.needHelp.verifyTeacher {
    margin: 10px auto 10px auto;
}

div.verifyTeacherContainer {
    display: flex;
    margin: 5px auto;
    flex-direction: column;
    background-color: #525174;
    padding: 10px;
    border-radius: 5px;
}

p.verifyTeacherNameText {
    display: flex;
    margin: auto;
    font-weight: 600;
    color: white 
}

div.verifyButtonContainer {
    display: flex;
    margin: 5px auto
}


p.verifyText {
    display: flex;
    margin: auto;
    font-weight: 600; 
}

div.verifyButtonYes {
    padding: 10px;
    display: flex;
    margin: auto 2px;
    width: 50%;
    height: 60px;
    background-color: green;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

div.verifyButtonNo {
    padding: 10px;
    display: flex;
    margin: auto 2px;
    width: 50%;
    height: 60px;
    background-color: red;
    border-radius: 5px;
    color: white;
    cursor: pointer; 
}

i.material-icons.verifyTeacher {
    margin: auto
}
