div.flashcardFormContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    margin: auto;
}

.flashcardFormContainer ol {
    padding-inline-start: 20px;
}

.flashcardFormContainer ul {
    margin-block-start: 0px;
}

div.flashcardFormContainerLeft {
    width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    margin: 0px auto auto auto;
}

//Theme Container
div.flashcardFormThemeContainerEdit {
    display:flex;
    flex-direction: row;
    margin: 10px auto auto auto;
}

p.flashcardFormSelectTheme {
    display: flex;
    margin: auto;
}

select.flashcardFormThemeDropdown {
    width: 100px;
    display: flex;
    margin: auto auto auto 10px;
}

div.flashcardFormThemeContainer {
    display:flex;
    margin: 10px auto auto auto;
    flex-direction: row;
    width: 150px;
}

p.flashcardFormThemeContainerText {
    display: flex;
    margin: auto;
}


//Title Container
div.flashcardFormFlexContainer {
    display: flex
}

div.flashcardFormTitle {
    display: flex;
    width: 50%;
    margin: auto;
}

div.innerflashcardFormContainerTitleText {
    display: flex;
    margin: auto 10px auto auto;
    max-width: 250px;
}

form.titleFlashcardFormContainer{
    position: relative;
}

p.flashcardFormSeries {
    display: flex;
    margin: auto;
    font-weight: 600;
    font-size: 1.2rem;
    position: relative;
    text-align: center
}

// Edit mode toggle
div.flashcardFormToggleContainerFlex{
    width: 50%;
    display: flex
}

div.toggleFlashcardFormContainer {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin: auto auto auto 10px;
    border-radius: 20px;  
}

div.toggleFlashcardFormContainer50Left {
    display: flex;
}

div.toggleFlashcardFormContainer50Right {
    width: 50%;
    display: flex;
}


p.flashcardFormPictureCardToggleLabel {
    margin: 0px
}

// Type Identifier

div.flashcardFormTextNoGraphContainer {
    display: flex;
    margin: auto;
    overflow: auto;
    height: 100%;
}

div.flashcardFormTextNoGraphTextContainer {
    display: flex;
    margin: auto;
}

.flashcardFormTextNoGraph p {
    margin:  0px 10px
}


i.material-icons.flashcardFormTypeIdentifier {
    display: flex;
    margin: auto;
    border-radius: 100px;
    background-color: #ffc728;
}

p.flashcardFormTypeIdentifier {
    margin-block-start: auto;
    margin: auto
}

div.rotateButton {
    display: flex;
    margin: auto auto auto 10px;
}

div.btn-floating.flashcardFormSave {
    display: flex;
    margin: auto
}

div.rotateButtonEdit {
    display: flex;
    margin: 10px auto;
}

p.rotateCard {
    color: black;
    font-size: 1.2rem;
    display: flex;
    margin: auto 5px auto 15px;
}

div.flashcardFormAutosaveTextContainer {
    display: flex;
    width: 50%;
    margin: auto 0px auto auto;
}

div.flashcardFormSaveTextContainer {
    display: flex
}

p.flashcardFormAutosaveText {
    color: grey;
    display: flex;
    margin: 30px auto auto 15px;
    position: absolute;
}


//

div.flashcardFormGraphContainerOuter{
    display: flex;
    flex-direction: column;
}

div.flashcardFormContainerLeftMiddle {
    display: flex;
    flex-direction: row;
    margin: auto; 
}

// Type Selection

div.flashcardFormTypeSelectionContainer{
    display: flex;
    margin: auto;
    flex-direction: column;
    // backface-visibility: hidden;
}

div.flashcardFormTypeSelectionContainerAlignCentre {
    display: flex;
    margin: auto
}

// Exam board selection
div.flashcardFormExamBoardContainerVerticalFlex {
    display: flex;
    flex-direction: column;
}

div.flashcardFormExamBoardContainer {
    display: flex;
    flex-direction: horizontal;
}


[type="checkbox"].flashcardFormCheckbox {
    position: relative;
    display: flex;
    margin: auto;
    top: initial;
}

p.flashcardFormExamBoardText {
    margin-block-start: auto;
    display: flex;
    margin: auto
}


div.flashcardFormBottomTextContainer {
    display: flex;
    padding: 10px;
    overflow: auto;
    max-height: 95px;
}

div.flashcardFormBottomTextContainer.true {
    z-index: -2 ;
}
div.flashcardFormBottomTextContainer.false {
    z-index: 2 ;        
}

.flashcardFormBottomTextContainer 
ul:not(.browser-default) > li {
    list-style-type: disc;
    margin-left: 5px;
}

div.flashcardFormBottomTextContainerEditMode {
    display: flex;
    position: absolute;
    bottom: 5px;
    height: 76px;
    width: 270px;
    padding: 5px;
    border: grey dashed 1px;
    margin: 5px;
}

form.questionFlashcardFormContainer{
    display: flex;
    margin: auto
}

div.toggleFlashcardFormContainerPictureCard {
    display: flex;
    flex-direction: row;
    margin: 5px 10px 0px auto;
    border-radius: 20px;
    z-index: 2;
    position: relative;
    top: 3px;
}

div.questionFlashcardFormContainerEditTextNoGraph {
    height: 350px;
    padding: 5px;
    border: grey dashed 1px;
    margin: auto 10px;
}

div.cke_editable {
    width: 100%;
    height:100%;
    border-radius: 10px;
    padding: 10px;
    cursor: text;
    overflow-y: auto;
    list-style-type: disc;
}

.cke_editable p {
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.cke_editable ul:not(.browser-default)>li {
    list-style-type: disc;
    margin-left: 10px;
    margin-block-start: 0px
}

.cke_editable ol {
    list-style-type: decimal;
    margin-left: 10px;
    margin-block-start: 0px
}



strong {
    font-weight: 600;    
}

div.flashcardFormGraphContainerEditMode{
    display: flex;
    position: relative;
    bottom: -4px;
}

img.flashcardFormGraphEditMode {
    position: relative;
    display: flex;
    width: 275px;
    height: 275px;
    border: grey 2px dashed;
    margin: auto;
}

img.flashcardFormGraphEditModeLarge {
    display: flex;
    width: 275px;
    height: 354px;
    border: grey 2px dashed;
    margin: auto;
}

div.input-field.flashcardFormImage {
    position: absolute;
    display: flex;
    left: 1px;
    top: 278px;
    margin-top: 0px;
    margin-bottom: 0px;
}

div.input-field.flashcardFormImageLarge {
    position: absolute;
    display: flex;
    left: 6px;
    top: 358px;
    margin-top: 0px;
    margin-bottom: 0px;
}

input.chooseFileflashcardFormImage {
    width: auto
}

div.flashcardFormContainerOuterEditNoGraph {
    display: flex;
    flex-direction: column;
    height: 100%;
}

p.flashcardFormTextGraph{
    padding: 0px 10px;
    margin: auto;
    width: 100%
}

// ::-webkit-scrollbar {
//     -webkit-appearance: none;
//     width: 7px;
//   }
// ::-webkit-scrollbar-thumb {
//     border-radius: 4px;
//     background-color: rgba(0, 0, 0, .5)
// }

.flashcardFormTextGraph p {
    margin-block-start: 0px;
}

.flashcardFormTextGraph ol {
    margin-block-start: 0px;
    padding-inline-start: 10px;
}

p.flashcardFormTextNoGraph{
    padding: 10px;
    margin: auto
}


.flashcardFormTextNoGraph ul:not(.browser-default)>li {
    list-style-type: disc;
    margin-left: 20px;
}

textarea#flashcardQuestion {
    color: rgb(95, 95, 95);
    padding: 5px;
    width: 270px;
    height: 65px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.30);
    border-radius: 5px;
    border: grey solid 1px;
}

textarea#flashcardAnswer {
    color: rgb(95, 95, 95);
    padding: 5px;
    width: 270px;
    height: 65px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.30);
    border-radius: 5px;
    border: grey solid 1px;
}

//Right container
i.material-icons.close {
    position: absolute;
    font-size: 45px;
    bottom: 5px;
    display: none
}

div.flashcardFormContainerRight {
    width: 35%;
    display: flex;
    flex-direction: column;
    margin: 0px auto auto auto;
}

p.toggleViewRightFlashcardForm {
    color: black;
    font-size: 1.2rem;
    display: flex;
    margin: auto 5px auto 15px;
}

div.flashcardFormVerticalContainer {
    display:flex;
    flex-direction: column;
}

div.flashcardFormContainer2 {  
    margin: auto;
    height: 400px;
    background: none;
    display: flex;
    overflow: hidden;
    width: none;
}

/* THE CARD HOLDS THE FRONT AND BACK FACES */
div.theCardForm{
    width: 300px;
    height: 100%;
    border-radius: 10px;
    transform-style: preserve-3d;
    transition: all 0.3s;
    display: flex;
    position: relative;
    margin: auto;
}

div.theCardForm.false{
    transform: rotateY(0deg);

    &.right {
        transform: translateX(800px);   
    }

    &.hiddenLeft {
        position: relative;
        display: flex;
        left: -1000px;
    }

    &.rightReturn {
        position: relative;
        display: flex;
        left: -800px;
        transform: translateX(800px);    
    }

    &.finished {
        display: flex;
        position: relative;
        transition: none;
    }

    &.left {
        transform: translateX(-800px);   
    }

    &.hiddenRight {
        position: relative;
        display: flex;
        right: -1000px;
    }

    &.leftReturn {
        position: relative;
        display: flex;
        right: -800px;
        transform: translateX(-800px);    
    }
    
}

div.theCardForm.true{
    transform: rotateY(180deg);
}

// Card content container

div.flashcardFormMiddleFlexContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
}

div.innerflashcardFormContainer {
    display: flex;
    flex-direction: column;
    height: 97%;
    width: 95%;
    border-radius: 5px;
    border-color: white;
    border-width: 2px;
    border-style: solid;
    margin: auto;
}

img.flashcardFormGraph {
    display: flex;
    width: 282px;
    height: 282px;
}

img.flashcardFormGraphLarge {
    display: flex;
    width: 282px;
    height: 382px;
}

div.flashcardFormGraphContainer {
    display: flex;
    height: 275px;
}

div.flashcardFormGraphContainerLarge {
    display: flex;
    height: 375px;
}

//Image placeholder

div.flashcardFormImagePlaceholder {
    position: relative;
    top: 0px;
    display: flex;
    flex-direction: column;
    width: 270px;
    height: 270px;
    border: grey 1px dashed;
    margin: auto auto auto auto;
}

div.flashcardFormImagePlaceholderLarge {
    position: absolute;
    top: -32px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 270px;
    height: 353px;
    border: grey 1px dashed;
    margin: 32px auto auto auto;
}



p.flashcardFormImagePlaceholder {
    display: flex;
    margin: auto auto auto auto;
    width: 80%;
    font-size: 25px;
    font-weight: 600;
    color: grey;
    opacity: 0.7
}

button.btn-floating.swapPicture {
    position: fixed;
    top: 262px;
    left: 15px;
}


/* THE FRONT FACE OF THE CARD, WHICH SHOWS BY DEFAULT */
div.flashcardFormFront{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    overflow: hidden;
    background-color: #ffc728;
    color: #000;
    display: flex
  }

  .flashcardFormFront p {
      font-weight: 600;
  }
  
  /* THE BACK FACE OF THE CARD, WHICH SHOWS ON MOUSEOVER */
  div.flashcardFormBack{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    overflow: hidden;
    background-color: #73b2d0;
    color: #333;
    transform: rotateY(180deg);
    display: flex
  }


p.flashcardFormText {
    display: flex;
    width: 80%;
    margin: auto;
    color: white;
    font-size: 1.5rem;
    font-weight: 600
}

// FLASHCARD PREVIEW
div.droppableArea {
    height: 75vh;
    border: 2px dashed grey;
    padding: 5px;
    margin: 10px;
    overflow: auto
}

div.flashcardPreview {
    display: flex;
    flex-direction: row;
    padding: 8px;
    margin: 8px;
    border: 2px solid white;
    background-color: #ffc728;
    border-radius: 5px;
    height: 60px;
}

div.dragItem {
    width: 25px;
    display: flex;
    margin: auto 0px auto 5px;
}

p.flashcardPreviewText {
    display: flex;
    flex-direction: column;
    margin: auto auto auto 5px;
    width: 70%;
    max-height: 100%;
    overflow: hidden;
}

.flashcardPreviewText p {
    display: flex;
    flex-direction: column;
}

.btn-floating.flashcardFormAddQuestion {
    display: flex;
    margin: 0px auto auto auto;
}

.div.btn-floating.red.flashcardFormRemoveQuestion {
    display: flex;
    text-align: center;
}

.btn-floating.flashcardFormRemoveQuestion {
    height: 30px;
    width: 30px;
    min-width: 30px;
    display: flex;
    margin: auto 5px auto 5px;
    box-shadow: none;
    text-align: center;
    background-color: transparent;
    border: 1px solid white;
}

i.material-icons.flashcardFormRemoveQuestion {
    display: flex;
    margin: auto;
    text-align: center;
    position: relative;
    left: 3px;
    bottom: 6px;
    color: black
}

// FLASHCARD BOTTOM NAV

div.flashcardFormButtonArrowContainer {
    background: none;
    display: flex;
    margin: 20px auto auto auto

}

div.flashcardNav{
    display: flex;
    margin: auto;
}

p.flashcardNavPagination {
    position: relative;
    margin: 10px 15px;
}

div.flashcardFormMenuButton {
    display: none;
}

.div.btn-floating.white {
    display: flex
}

i.material-icons.menu {
    color: grey
}


//MEDIA QUERIEs

@media (max-width: 480px) {
    div.flashcardFormContainer2 {
        width: auto;
    }

    div.flashcardContainer {  
        width: 80%;
        height: 400px;
    }

    div.innerflashcardContainer {
        height: 97%;
        width: 97%;
    }

    div.innerflashcardFormContainerTitleText {
        position: initial; 
        left: initial;
        margin: auto;
    }

    div.flashcardFormContainerRight {
        display: none;
        right: -100%;
        width: 250px;
        margin: auto;
        padding: 10px;
        background-color: #525174;
        opacity: 1;
        z-index: 2;
        transition: all 0.3s;

        &.display {
            height: 95vh;
            right: 0px;
            display: flex;
            position: absolute;
        }
    }

    div.flashcardFormThemeContainer {
        display: none;
    }

    div.btn-floating.flashcardFormAddQuestion {
        display: none
    }


    i.material-icons.close {
        display: flex
    }

    div.droppableArea {
        height: 85vh;
        border: none;
        padding: 5px;
        margin: 5px;
        overflow: auto;
    }

    div.toggleFlashcardFormContainer {
        display: none;
    }

    div.flashcardFormTitle {
        margin-top: 10px
    }

    div.flashcardFormMenuButton {
        width: 20%;
        display: flex;
        margin: auto 0px auto auto
    }


}