.bottom-nav-wrapper {
    display: none;
    height: 10vh;
    position: fixed;
    bottom: 0%;
    background-color: transparent;    
}

div.bottomNavButtonContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
}

a.material-icons.bottomNav {
    font-size: 2rem;
    color: grey;
    margin: auto;
}

a.material-icons.bottomNav.active {
    color: purple;
}

p.mobileNavBarLabel {
    color: grey;
    display: flex;
    font-size: 1rem;
    margin: auto;
    line-height: initial;
}

p.mobileNavBarLabel.active {
    color: purple;
}

@media (max-width: 480px) {
    .bottom-nav-wrapper {
        display: flex;
        z-index: 2;
        position: fixed;
        background-color: whitesmoke;
    }
}