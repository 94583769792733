div.upvotesInfo {
    margin: auto 0px auto auto;
    display: flex
}

div.innerThemeContainer1 { 
    display: flex;
    width: 100%;
    background-color: transparent;
    margin: auto auto auto auto;
    padding: 0 20px 0 20px;
    border-radius: 5px;
}

div.innerThemeContainer.locked { 
    background-color: #6a726e;
}

.Collapsible__trigger:before {
    display: flex;
    margin: auto;
    transition: transform 300ms;
    position: initial;
    left: initial;
    top: initial;
}

.Collapsible__trigger.is-closed{
    display: flex;
}

.Collapsible__trigger.is-closed:before{
    font-family: 'FontAwesome';
    content: '\f107';
    display: flex;
    margin: auto;
    transition: transform 300ms;
    color: #525174
}

p.quizNameCollapsible.text {
    color: #525174
}

p.quizLandingInnerHighScore {
    color: white;
    font-size: 1.5rem !important;
    text-align: right;
    height: auto;
    width: 50%;
    margin: auto 5px auto auto;
} 


.Collapsible__contentInner p:last-child {
    margin-bottom: auto;
}

ul.bigContainer {
    width: 100%;
    font-size: 1.8rem; 
}

li.themeListContainer {
    width: 100%;
    
}

li.themeListContainer p {
    margin-block-start: 0px;
    display: flex;
    margin: auto auto auto 0px;
    margin-block-start: 0px;
    line-height: initial;
    margin-block-end: 0px;
}

li.quizLandingQuizName {
    display: flex;
    width: 100%
}

div.themeButtonContainer {
    display: flex;
    margin: auto 0px auto auto;
}

a.material-icons.quizLandingRemoveIcon {
    color: black;
    display: flex;
    margin: auto 0px auto auto; 
    transition: color .3s ease, border-color .3s ease, transform .3s ease;

    &:hover {
        transform: scale(1.05);
        color: green;
        cursor: pointer;
    }
}

i.material-icons.quizLandingRemoveIcon {
    display: flex;
    margin: auto 0px auto auto; 
    transition: color .3s ease, border-color .3s ease, transform .3s ease;

    &:hover {
        transform: scale(1.05);
        color: green;
        cursor: pointer;
    }
}

li.quizLandingQuizName p{
    margin-block-start: 0px;
    margin-block-end: 0px;
}

div.quizLandingQuizNameContainer {
    display: flex;
    max-width: 200px;
    background-color: #525174;
    margin: 5px;
    padding: 5px;
    border-radius: 10px;
}

div.quizLandingQuizNameContainer:hover {
    cursor: pointer;
}

a.quizLandingQuizName {
    color: white;
    margin-left: 10px;
    width: 90%;
}

div.quizLandingFlexRow {
    display: flex;
    flex-direction: row;
    margin: 5px 0px;
}

div.quizLandingflashcardIconFlexRow {
    display: flex;
    flex-direction: row;
    position: relative;
    bottom: 2px;
    margin: auto 10px auto auto;
}


@media (max-width: 480px) {
    ul.bigContainer {
        width: 100%;
        font-size: 1.2rem; 
    }
    a.quizName {
        color: white;
        width: 100%
    }
    p.innerHighScore {
        color: black;
        text-align: right;
        height: auto;
        margin: auto;
    }
    div.innerThemeContainer { 
        display: flex;
        width: 90%;
        background-color: #5dd39e;
        margin: 5px auto 5px auto;
        padding: 0 10px 0 10px;
    }
}
