div.teacherFeedTitleContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 15px auto;
}

div.teacherFeedTitleTextContainer {
    display: flex;
    flex-direction: row;
    margin: auto
}

div.btn-floating.waves-effect.waves-light.red.teacherFeedNotification {
    display: flex;
    margin: auto auto auto 10px;
    width: 25px;
    height: 25px;
    line-height: normal;
    box-shadow: none;
    cursor: initial;
}

p.teacherFeedNotificationNumber {
    display: flex;
    margin: auto;
    font-weight: 600;
    font-size: 12px;
}

div.teacherFeedLink {
    margin: auto
}

p.feedTitle {
    display: flex;
    color: rgb(77, 77, 77);
    font-size: 1.2rem;
    font-weight: 600;
    margin: auto 5px auto auto
}

i.material-icons.teacherFeedTitle {
    display: flex;
    color: rgb(77, 77, 77);
    margin: auto auto auto 5px
}

i.material-icons.feedList {
    display: flex;
    margin: auto 20px auto 0px;
    width: 30px;

    &:hover {
        cursor: pointer;
    }
}



div.teacherFeedContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: #bfbfbf solid 1px;
    border-radius: 5px;
    height: 85vh;
    background-color: white;
}



//Scrollable Feed

div.teacherFeedPlaceholder {
    border: grey 1px dashed;
    border-radius: 5px;
    background-color: #edeef5;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;

}

div.teacherFeedPlaceholderContainer {
    display: flex;
    margin: 0px auto;
}

div.teacherFeedPlaceholderInnerContainer {
    display: flex;
    margin: auto;
    flex-direction: column;
    max-width: 800px;
}

i.material-icons.teacherFeedPlaceholder {
    display: flex;
    margin: auto 10px
}

p.teacherFeedPlaceholderText.title {
    margin: 10px auto 10px auto;
    font-weight: 600;
    color: #515174;
    font-size: 1.5rem;
}

a.btn.centre.teacherFeed.onboarding {
    max-width: 300px;
    display: flex;
    margin: 5px auto 15px auto;
}

a.btn.centre.teacherFeed.onboarding.classview {
    max-width: 300px;
    display: flex;
    margin: 5px auto 20px 0px;
    font-weight: 600;
    background-color: #515174;
}

p.teacherFeedPlaceholderText {
    margin: 0px auto 0px 0px;
    color: #515174;
}

p.teacherFeedPlaceholderText.sub {
    margin: 0px auto 0px 0px;
    font-weight: 600;
    color: #515174;
}



div.teacherFeedFeedContainer {
    height: 100%;
    overflow-y: auto;
}

div.flexRowTeacherFeed {
    display: flex;
    flex-direction: row;
    margin-right: 15px
}

div.teacherFeedTextContainer {
    display: flex;
    flex-direction: column;
    margin: 2px auto;
    background-color: #e5e5ff;
    width: 85%;
    border-radius: 10px;
    padding: 0px 10px
}

span.teacherFeedStudentLink {
    color: #039be5;

    &:hover {
        cursor: pointer;
    }
}

p.teacherFeedMain {
    color: #797979;
    margin: auto auto auto 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 600;
    text-align: left;
}
p.teacherFeedSecondary {
    color:grey;
    margin: auto auto auto 0px
}

div.btn-floating.teacherFeed {
    display: flex;
    margin: auto 5px;
    background-color: #525174;
}

div.btn-floating.teacherFeed.popup {
    border-radius: 30px;
    margin: auto 5px
}

a.teacherFeedLink {
    margin: auto
}

span.teacherFeedLink {
    color: #039be5;
    cursor: pointer
}

p.teacherFeedButtonText {
    text-align: center;
    margin: auto;
}

div.btn.centre.teacherFeed.onboarding {
    max-width: 300px;
    display: flex;
    margin: 5px auto 15px auto;
}

div.btn.centre.teacherFeed.onboarding.classview {
    max-width: 300px;
    display: flex;
    margin: 5px auto 15px 0px;
    font-weight: 600;
    background-color: #515174;
}

@media (max-width: 480px) {
    div.teacherFeedContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: transparent;
        padding: 0px;
        width: 100%;
        border: none
    }

    div.teacherFeedPlaceholder {
        margin: 5px    
    }

    div.teacherFeedTitleContainer {
        background-color: #525174;
        color: white;
        margin: 0px auto 5px auto;
        padding: 15px;
        width: 100%;
        box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
    }

    p.feedTitle {
        color:white;
        font-size: 1.3rem;
    }
    
    i.material-icons.teacherFeedTitle {
        color:white;
    }

    i.material-icons.feedList {
        margin: auto 0px auto 0px;
        width: initial;
    }

    div.btn-floating.teacherFeed.popup {
        margin: auto;
        border-radius: 30px
    }

}
