div.signInHeader{
    display: flex;
    flex-direction: row;
}

a.newUser {
    font-size: 1rem;
    display: flex;
    margin: auto auto auto 20px;
    color: white;
    border-radius: 5px;
    background-color: #525174;
    vertical-align: middle;
    padding: 5px 8px
}