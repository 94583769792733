div.flashcardFormTitleBeta {
    display: flex;
    width: 80%;
    margin: auto;
}

div.innerflashcardFormContainerTitleTextBeta {
    display: flex;
    margin: auto auto auto auto;
    position: relative;
    max-width: 400px;
}

a.flexColumnflashcardFormBetaQuizLink {
    display: flex;
    margin: auto auto auto 20px;
    color: white;
    font-weight: 600;
    background-color: #525174;
    padding: 5px 15px;
    border-radius: 10px
}

a.bicep {
    display:flex;
    border: black 1px solid;
    width: 60px;
    border-radius: 5px;
    margin-right: 5px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

p.bicep {
    margin: auto 5px;
    display: flex;
    text-align: center;
    font-size: 2rem;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

div.flexColumnflashcardFormBetaQuizLink {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 40px;
    margin-left: 20px
}

div.flashcardFormBottomTextContainer.true {
    z-index: -2 ;
}
div.flashcardFormBottomTextContainer.false {
    z-index: 2 ;        
}

div.flexColumnflashcardFormBetaQuizLinkMobile {
    display: none;
}

p.bicepBetaLabel {
    white-space: nowrap;
    display: flex;
    margin: auto;
}

a.flashcardFormBetaHeaderTopContainerThird{
    display: none
}

div.mcqGraphHeaderTopContainerThird {
    display: none
}

i.material-icons.toggleFlashcardSidebar {
    display: none
}



@media (max-width: 450px) {
    i.material-icons.toggleFlashcardSidebar {
        display: flex;
        color: white;
        margin-left: 5px
    }


    div.flashcardFormButtonArrowContainer {
        width: 95%
    }
    
    div.btn-floating.MenuWhite {
        display: flex;
        margin: auto 0px auto auto;
        background-color: white;    
    }

    div.emptyThird {
        width: 20%;
        display: flex
    }

    div.flashcardFormTitleBeta {
        width: 95%;
    }

    div.innerflashcardFormContainerTitleTextBeta {
        width: 100%
    }

    div.mcqGraphHeaderTopContainerThird {
        display: flex
    }
    
    a.flashcardFormBetaHeaderTopContainerThird{
        display: flex;
        margin: auto;
        width: 20%;
    }

    a.flexColumnflashcardFormBetaQuizLink {
        display: none;
    }

    div.flexColumnflashcardFormBetaQuizLinkMobile {
    position: relative;
    display: inline-block;
    flex-direction: column;
    margin-right: 20px;

    }

    div.flexColumnflashcardFormBetaQuizLink {
        display: none;
    }

    
}
