div.greetingDashboardMobile {
    display: none;
}

.dashboardContainer {
    display: block;
    width: 100%;
    padding-bottom: 20px
}

.dashboardHeader {
    border: grey solid 1px;
    padding: 10px;
    width: 100%;
    /* background-color: #348aa7; */
    display: flex;
    border-radius: 10px;
    margin: 10px auto auto auto;
}

.dashboardHeaderStudent {
    padding: 10px;
    width: 100%;
    /* background-color: #348aa7; */
    display: flex;
    border-radius: 10px;
    margin: 20px auto auto auto;
    max-width: 750px;
}

div.dashBoardContainerProgress {
    width: 90%;
    margin: auto
}

div.dashboardHeaderLeftContainer {
    display: flex;
    width: 60%;
}

div.dashboardHeaderRightContainer {
    display: flex;
    flex-direction: column;
    margin: auto
}

.dashboardHeaderTextContainer {
    width: 70%;
    margin-left: 3%;
    display: flex;
    flex-direction: column; 
}

.dashboardHeaderTextContainerStudent {
    width: 70%;
    margin: auto 25px;
    display: flex;
    flex-direction: column; 
}

p.dashboardName {
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    margin: auto auto 2px 0px;
    line-height: 1
}

p.dashboardSchool {
    font-size: 1.5rem;
    display: flex;
    margin: 2px auto auto 0px;
    color: grey
}


div.ghostedImage {
    height: 100%;
    width: 100px;
    display: flex;
    margin: auto auto auto 2%
}

div.ghostedImageStudent {
    height: 100%;
    display: flex;
    margin-left: 5%
}

img.school {
    opacity: 0.1;
    float: left;
    height: 100px;
    width: 100px;
    display: flex;
    margin: auto;
}

 /*  */

 h1.dashboardElementTextProgress {
    font-size: 2rem;
    display: flex;
    margin: 20px auto 10px auto;
    height: 40px;
    font-weight: 600;
}

h1.dashboardElementTextAccuracy {
    font-size: 2rem;
    display: flex;
    margin: 20px auto 27px auto;
    height: 40px;
    font-weight: 600;

}



.dashboardContentContainer {
    height: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
    max-width: 750px;
}

// @keyframes slideInFromBottom {
//   0% {
//     transform: translateY(100%);
//   }
//   100% {
//     transform: translateX(0);
//   }
// }


.dashboardContainerLeft {
    width: 100%;
    display: flex;
    margin: 20px 10px;
    // animation: 1s ease-out 0s 1 slideInFromBottom;
}

.dashboardContainerLeftElement {
    max-height: 60vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin: 0px 2px 0px 0px;
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(214, 214, 214);
    border-radius: 5px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.05);
}

.dashboardContainerRightElement {
    max-height: 60vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 2px;
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(214, 214, 214);
    border-radius: 5px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.05);
}

p.dashboardElementText {
    text-align: left;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    margin: auto;
    color:  #525174;
}

a.dashboardElementTheme {
    font-size: 1.1rem;
    font-weight: 600;
    width: 70%;
    display: flex;
    margin: 0px auto 0px 10px;
    color:  #525174;
    white-space: nowrap;
    
}

a.dashboardElementTest {
    font-size: 1rem;
    height: 90%;
    display: flex;
    margin: auto;
    color: white;
    border-radius: 5px;
    background-color: #525174;
    vertical-align: middle;
    padding: 3px 6px 
}

.dashboardContainerRight {
    height: 400px;
    display: flex;
    margin: 0px auto 0px auto
}

div.dashboardElement { 
    display: flex;
    margin: 0px auto 0px 5px;
    height: 30px;
    padding: 0;
    width: 95%
}


@media (max-width: 480px) {
    div.mobileContainer {
        height:90vh;
        overflow: auto;
        width: 100%;
    }

    .dashboardContentContainer {
        flex-wrap: wrap;
    }

    div.dashboardHeaderLeftContainer {
        display: none
    }

    div.greetingDashboardMobile {
        display: flex;
        margin: 0px 0px 15px 0px;
    }

    .dashboardContainerLeftElement {
        margin: 0px;
    }

    p.dashboardName {
        font-size: 2rem;
        margin: auto;
        color: purple
    }

    .dashboardHeader {
        padding: 0 5%;
    }

    .dashboardHeaderTextContainer {
        display: none
    }

    p.dashboardSchool {
        margin-left: 5%;
    }

    div.ghostedImage {
        display: none
    }

}