/* Adding pagination */

.topContainerFatFinger {
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: #73b2d0;
    border-radius: 5px;
}

ul.pagination {
        display: flex;
        margin: 10px auto auto auto;
        border-style: solid;
        border-width: 2px;
        border-color: #0084da;
        border-radius: 10px;
        padding: 5px 0px; 
}

.pagination li {
    align-items: center;
    display: flex;
    flex-direction: row;
    border-radius: 2px;
    text-align: center;
    vertical-align: top;
    height: 40px;
    width: 40px;
    margin: auto;
}


.pagination li a {
    color: #444;
    font-size: 1rem;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #0084da;
}

.pagination li.active {
    background-color: #26a69a;
    border-radius: 20px;
}

/* Graph template specific formatting */

.topContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 10px;
    border-radius: 5px
}

#question {
    
    color: white;
    padding: 10px;
    text-align: center;
    width: 100%
}

#image {
    display: flex;
    width: 250px;
    height: 250px;
    margin: 10px;
}

.graph {
    width: 250px;
    height: 250px;
    display: flex
}

/*  */
div.col-lg-10.col-lg-offset-1 {
    margin-top: 5px;
}

#question h4{
    background-color: #fff;
    color: #333333;
    display:inline-block;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 600;
}

#question p{
    font-size: 20px;
    padding-left: 50px;
    padding-right: 50px;
}

#answers ul{
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#answers li {
    background-color: #fff;
    border: 2px solid  #73b2d0;
    border-radius: 5px;
    min-height: 70px;
    width: 48%;
    display: flex;
    margin-bottom: 30px;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    cursor: pointer;
}

/* #answers li span {
    background-color:  #73b2d0;
    color: #fff;
    font-size: 30px;
    flex: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .3s ease;
} */

#answers li p {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    flex: calc(100% - 75px);
    margin: auto 20px;
    transition: color .3s ease;
}

#answers li:after{
    display: block;
}

#answers li:hover {
    transform: scale(1.03);
}

#answers li.right {
    border-color: #1ea55b;
    color: #1ea55b;
}

/* #answers li.right span {
    background-color: #1ea55b;
} */

#answers li.right p {
    color: #1ea55b;
}

#answers li.wrong {
    border-color: #dc0a0a;
    color: #dc0a0a;
}

#answers li.wrong span {
    /* background-color: #dc0a0a; */
}

#answers li.wrong p {
    color: #dc0a0a;
}

#submit {
    text-align: center;
    margin: 20px 0;
}

.fancy-btn {
    border: 2px solid #0b0e21;
    border-radius: 500px;
    background-color: #0b0e21;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 75px;
    margin: 0 auto;
    text-transform: uppercase;
    transition: color .2s ease, background-color .2s ease;
}

.fancy-btn:hover {
    background-color: #fff;
    color: #0b0e21;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.popup h1 {
    background-color: #0094da;
    color: white;
    border-bottom: 1px solid #ccc;
    padding: 20px;
    margin-top: 0;
}

.popup p {
    font-size: 18px;
    letter-spacing: 1px;
    margin: 20px 10% 0;
}

.popup .fancy-btn{
    margin: 20px 10px 20px 10px;
}



footer {
    background-color: #0b0e21;
    color: white;
    letter-spacing: 1px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    text-align: center;
}

footer p {
    margin: 0;
}

footer small {
    margin-bottom: 10px;
}

footer span {
    color: crimson;
}

.bigButton {
    width: 80%;
    background-color: #0094da;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    margin: auto auto 15px auto;

}

.bigButton .bigButtonText{    
    display: flex;
    margin: auto auto auto 30px;
    color: white;
}

.bigButton .openArrow{    
    display: flex;
    margin: auto auto auto 5px;
    font-size: 30px;
    color: white;
}

.categoryContainers {
    width: 50%;
    margin: auto auto auto 200px;
    font-size: 30px;
    color: white;
    display: flex;
}

.gapsQuizContainer {
    width: 80%;
    min-height: 400px;
    background-color: #0094da;
    display: flex;
    margin: auto;
    border-radius: 5px;
}

.gapsQuizContainer p {
    color: white;
    font-size: 1.8rem;
    word-spacing: 0.5rem;
    padding: 20px;
}

.gapsQuizContainer span {
    color: black;
    background-color: white;
    border-radius: 3px;
}

@media (max-width: 768px) {
    #question p {
        padding-left: 10px;
        padding-right: 10px;
    }
    
    #answers li {
        width: 100%;
    }
    
    #submit button {
        width: 100%;
    }
}
@media (max-width: 480px) {
    .topContainer {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-top: 10px
    }

    .topContainerFatFinger {
        display: flex;
        width: 100%;
        flex-direction: column;
        background-color: #73b2d0;
        padding-top: 10px;
        border-radius: 5px;

    }

    #answers ul {
        margin-top: 5px;
    }

    #answers li {
        margin-bottom: 5px;
    }
    
    
    #image {
        display: flex;
        width: 250px;
        height: 250px;
        margin: auto;
    }
    
    footer {
        padding: 5px;
    }
    
    footer small {
        display: none;
    }
}

.formContainer {
    width: 80%;
    min-height: 40px;
    background-color: #0094da;
    margin: 2px auto 2px auto;
    border-radius: 5px;
    color: black;
    font-size: 1.8rem;
    word-spacing: 0.5rem;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.questionTitle {
    margin: 10px auto 10px auto;
    padding: 10px 30px 10px;
}

.formQuestion {
    width: 95%;
    min-height: 150px;
    margin: 0px auto 0px auto;
    border-radius: 5px;
    display: flex;
    padding: 10px;
}

.answerContainer {
    width: 95%;
    padding: 10px;
    margin: 5px auto 5px auto;
    display: flex;
    flex-direction: row;
    border-style: solid;
}

.formContainerTop {
    width: 100%;
    margin: 0px auto 10px auto;
}

.answerContainerleft {
    width: 70%;
}

.answerContainerright {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.correctAnswer {
    margin: auto;
}

.formAnswer {
    width: 100%;
    border-radius: 5px;
    display:inline-block;
    margin: auto;
}

.formExposition {
    width: 100%;
    min-height: 100px;
    border-radius: 5px;
    display:inline-block;
    margin: auto;
}

.correctButton {
    display: flex;
    margin: auto !important;
}


